import React from 'react'
import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../../components/card/Card.jsx'
import { TabNew } from '../../../../components/tab/Tab.jsx'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { getSegmentUrl } from '../../../../utilitys/helper'
import FormFollowUpAuditManagerTabIndex from './manager/FormFollowUpAuditManagerTabIndex'
import FormFollowUpAuditActivityTabIndex from './activity/FormFollowUpAuditActivityTabIndex'
import FormFollowUpAuditResultfollowTabIndex from './resultfollow/FormFollowUpAuditResultfollowTabIndex'
import FormFollowUpAuditCloseissueTabIndex from './closeissue/FormFollowUpAuditCloseissueTabIndex'
import FormFollowUpAuditFollowNumberTabIndex from './follownumber/FormFollowUpAuditFollowNumberTabIndex'

const FormFollowUpAuditIndex = (props) => {
  const { state } = useLocation()
  if (!state) return <Navigate to={'/followup/followupaudit/'} />
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = getSegmentUrl(3)
  const type = getSegmentUrl(4)

  const breadcrumbList = [
    { label: t('งานติดตามผลการตรวจสอบ'), path: '/followup/followupaudit' },
    { label: t('ติดตามผลการตรวจสอบ'), path: '/followup/followupaudit' },
    { label: t('กิจกรรมการตรวจสอบ') }
  ]
  const tabs = [
    {
      key: 1,
      iconTab: () => <></>,
      name: t('บันทึก ผจ.ลบ'),
      disabled: type === 'follow' ? true : false,
      content: () => <FormFollowUpAuditManagerTabIndex menu={props.menu} refCode={state.refCode} />
    },
    {
      key: 2,
      iconTab: () => <></>,
      name: t('กิจกรรมตรวจสอบ'),
      disabled: type === 'follow' ? true : false,
      content: () => <FormFollowUpAuditActivityTabIndex menu={props.menu} refCode={state.refCode} />
    },
    {
      key: 3,
      iconTab: () => <></>,
      name: t('บันทึกผลการติดตาม'),
      disabled: type === 'follow' ? true : false,
      content: () => <FormFollowUpAuditResultfollowTabIndex menu={props.menu} refCode={state.refCode} />
    },
    {
      key: 4,
      iconTab: () => <></>,
      name: t('ปิดประเด็น/ข้อสังเกต'),
      disabled: type === 'follow' ? true : false,
      content: () => <FormFollowUpAuditCloseissueTabIndex menu={props.menu} refCode={state.refCode} />
    },
    {
      key: 5,
      iconTab: () => <></>,
      name: t('จำนวนครั้งที่ติดตาม'),
      disabled: type === 'follow' ? true : false,
      content: () => <FormFollowUpAuditFollowNumberTabIndex menu={props.menu} refCode={state.refCode} />
    }
  ]
  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('กิจกรรมการตรวจสอบ')} menu={props.menu} />
      <Row>
        <Col md={24}>
          <CardNew
            topic={t('รายการกิจกรรมตรวจสอบ')}
            icon={type === 'follow' ? 'Plus' : type === 'edit' ? 'Edit2' : 'FileText'}
            toggledrop="false"
          >
            <TabNew
              tab={tabs}
              activeKey={
                params === 'manager'
                  ? '1'
                  : params === 'activity'
                  ? '2'
                  : params === 'resultfollow'
                  ? '3'
                  : params === 'closeissue'
                  ? '4'
                  : params === 'follownumber'
                  ? '5'
                  : ''
              }
              onTabClick={(key) => {
                if (key === '1') {
                  navigate('/followup/followupaudit/manager/' + type, {
                    state: { refCode: state.refCode }
                  })
                } else if (key === '2') {
                  navigate('/followup/followupaudit/activity/' + type, {
                    state: { refCode: state.refCode }
                  })
                } else if (key === '3') {
                  navigate('/followup/followupaudit/resultfollow/' + type, {
                    state: { refCode: state.refCode }
                  })
                } else if (key === '4') {
                  navigate('/followup/followupaudit/closeissue/' + type, {
                    state: { refCode: state.refCode }
                  })
                } else if (key === '5') {
                  navigate('/followup/followupaudit/follownumber/' + type, {
                    state: { refCode: state.refCode }
                  })
                }
              }}
            />
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default FormFollowUpAuditIndex
