import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Form } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { GET_AUDIT_CAUSE_OF_ISSUES_BY_ID } from '../../graphql/Query'
import { SAVE_AUDIT_CAUSE_OF_ISSUES } from '../../graphql/Mutation'
import SelectRiskFactorExamine from '../../../../../components/inputfromapi/selectriskfactorexamine/SelectRiskFactorExamine'
import SelectRateRiskRulesExamine from '../../../../../components/inputfromapi/selectrateriskrulesexamine/SelectRateRiskRulesExamine'
import SelectRateRiskControlExamine from '../../../../../components/inputfromapi/selectrateriskcontrolexamine/SelectRateRiskControlExamine'
import SelectAuditIssues from '../../../../../components/inputfromapi/selectauditIssues/SelectAuditIssues'
import SelectCauseOfIssue from '../../../../../components/inputfromapi/selectcauseofIssue/SelectCauseOfIssue'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { displayText, displayError } from '../../../../../utilitys/helper'

const AuditResultTwoModal = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { objData, setStatus } = props
  const [disabled, setDisabled] = useState(false)
  const [rateFacProCode, setRateFacProCode] = useState(null)
  const [rateRiskRuleCode, setRateRiskRuleCode] = useState(null)
  const [rateRiskConCode, setRateRiskConCode] = useState(null)

  const [cellGet, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(
    GET_AUDIT_CAUSE_OF_ISSUES_BY_ID
  )

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(SAVE_AUDIT_CAUSE_OF_ISSUES)

  useEffect(() => {
    setDisabled(false)
    if (objData.type === 'edit' || objData.type === 'view') {
      const variables = encryptInput({ causeId: objData.causeId })
      cellGet({ variables })
    }
    if (objData.type === 'view') {
      setDisabled(true)
    }
  }, [objData])

  useEffect(() => {
    if (dataGet) {
      setRateFacProCode(dataGet.auditCauseOfIssuesById.rateFacProCode)
      setRateRiskRuleCode(dataGet.auditCauseOfIssuesById.rateRiskRuleCode)
      setRateRiskConCode(dataGet.auditCauseOfIssuesById.rateRiskConCode)
      form.setFieldsValue({
        rateFacProCode: dataGet.auditCauseOfIssuesById.rateFacProCode,
        rateRiskRuleCode: dataGet.auditCauseOfIssuesById.rateRiskRuleCode,
        rateRiskConCode: dataGet.auditCauseOfIssuesById.rateRiskConCode,
        issueId: dataGet.auditCauseOfIssuesById.issueId,
        causeCode: dataGet.auditCauseOfIssuesById.causeCode,
        causeDetail: displayText(
          dataGet.auditCauseOfIssuesById.causeDetailTH,
          dataGet.auditCauseOfIssuesById.causeDetailEN
        )
      })
    }
    if (errorGet) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorGet)),
        onOk() {}
      })
    }
  }, [dataGet])

  useEffect(() => {
    if (dataSave) {
      if (dataSave.auditCauseOfIssuesSave.causeCode) {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            setStatus(true)
            form.resetFields()
            props.close(false)
          }
        })
      }
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {}
      })
    }
  }, [dataSave])

  const onFinish = (input) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        showConfirm(input)
      },
      onCancel() {}
    })
  }

  const showConfirm = async (input) => {
    let data = {}
    if (objData.causeId) data = { causeId: objData.causeId }
    let dataInput = {
      ...data,
      rateFacProCode: input.rateFacProCode,
      rateRiskRuleCode: input.rateRiskRuleCode,
      rateRiskConCode: input.rateRiskConCode,
      issueId: input.issueId,
      causeCode: input.causeCode,
      causeDetailTH: input.causeDetail,
      causeDetailEN: input.causeDetail,
      isActive: 1,
      typeAction: objData.type,
      lang: localStorage.getItem('lang') || ''
    }

    await cellSave({
      variables: {
        input: encryptInput(dataInput)
      }
    })
  }

  function onClose(e) {
    form.resetFields()
    props.close(e)
  }

  const testTitle =
    objData.type === 'add'
      ? t('เพิ่มสาเหตุประเด็น')
      : objData.type === 'edit'
      ? t('แก้ไขสาเหตุประเด็น')
      : t('ดูสาเหตุประเด็น')

  return (
    <>
      {(loadingSave || loadingGet) && <SpinnersNew />}
      <ModalNew
        typeAction={objData.type}
        visible={props.open}
        testTitle={testTitle}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2
          }}
          wrapperCol={{
            span: 10
          }}
          layout="horizontal"
          name="auditResultTwoModal"
        >
          <Form.Item
            name="rateFacProCode"
            label={<Label type="tab-header-inactive">{t('ปัจจัยความเสี่ยง')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุปัจจัยความเสี่ยง') }]}
          >
            <SelectRiskFactorExamine
              disabled={disabled}
              projectCode={props.projectCode}
              filters={{ approveStatusCode: 20 }}
              formname="auditResultTwoModal"
              placeholder={t('เลือกปัจจัยความเสี่ยง')}
              handleChange={(e) => {
                setRateFacProCode(e?.value || null)
                setRateRiskRuleCode(null)
                setRateRiskConCode(null)
                form.setFieldsValue({
                  rateFacProCode: e?.value || null,
                  rateRiskRuleCode: null,
                  rateRiskConCode: null
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name="rateRiskRuleCode"
            label={<Label type="tab-header-inactive">{t('เกณฑ์ระเบียบคำสั่ง')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุเกณฑ์ระเบียบคำสั่ง') }]}
          >
            <SelectRateRiskRulesExamine
              disabled={disabled}
              rateFacProCode={rateFacProCode}
              placeholder={t('เลือกเกณฑ์ระเบียบคำสั่ง')}
              formname="auditResultTwoModal"
              handleChange={(e) => {
                setRateRiskRuleCode(e?.value || null)
                form.setFieldsValue({ rateRiskRuleCode: e?.value || null, rateRiskConCode: null })
              }}
            />
          </Form.Item>
          <Form.Item
            name="rateRiskConCode"
            label={<Label type="tab-header-inactive">{t('วิธีการควบคุม')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุวิธีการควบคุม') }]}
          >
            <SelectRateRiskControlExamine
              disabled={disabled}
              rateRiskRuleCode={rateRiskRuleCode} // ข้อมูล test
              placeholder={t('เลือกวิธีการควบคุม')}
              formname="auditResultTwoModal"
              handleChange={(e) => {
                setRateRiskConCode(e?.value || null)
                form.setFieldsValue({ rateRiskConCode: e?.value || null })
              }}
            />
          </Form.Item>
          <Form.Item
            name="issueId"
            label={<Label type="tab-header-inactive">{t('ข้อสังเกต')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุข้อสังเกต') }]}
          >
            <SelectAuditIssues
              disabled={disabled}
              // projectCode={props.projectCode}
              filters={{
                projectCode: props.projectCode,
                issueType: '1',
                rateFacProCode: rateFacProCode,
                rateRiskRuleCode: rateRiskRuleCode,
                rateRiskConCode: rateRiskConCode
              }}
              formname="auditResultTwoModal"
              placeholder={t('เลือกข้อสังเกต')}
              handleChange={(e) => {
                form.setFieldsValue({
                  issueId: e?.value || null
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name="causeCode"
            label={<Label type="tab-header-inactive">{t('สาเหตุ')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุสาเหตุ') }]}
          >
            <SelectCauseOfIssue
              disabled={disabled}
              formname="auditResultTwoModal"
              placeholder={t('เลือกระบุสาเหตุ')}
              handleChange={(e) => {
                form.setFieldsValue({
                  causeCode: e?.value || null
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name="causeDetail"
            label={<Label type="tab-header-inactive">{t('คำอธิบาย')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุคำอธิบาย') }]}
          >
            <TextAreaNew showCount disabled={disabled} maxLength={300} rows={5} placeholder={t('ระบุคำอธิบาย')} />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default AuditResultTwoModal
