import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Form, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { IconNew } from '../../../components/icon/Icon.jsx'
import { InputNew } from '../../../components/input/Input'
import SelectAuditIssues from '../../../components/inputfromapi/selectauditIssues/SelectAuditIssues'
import SelectCauseOfIssue from '../../../components/inputfromapi/selectcauseofIssue/SelectCauseOfIssue'
import SelectRateRiskControl from '../../../components/inputfromapi/selectrateriskcontrol/SelectRateRiskControl'
import SelectRateRiskFactorProcessModel from '../../../components/inputfromapi/selectrateriskfactorprocessmodel/SelectRateRiskFactorProcessModel'
import SelectRateRiskRulesModel from '../../../components/inputfromapi/selectrateriskrulemodel/SelectRateRiskRuleModel'
import { LabelNew } from '../../../components/label/Label'
import { ModalNew } from '../../../components/modal/Modal'
import { Select } from '../../../components/select/Select'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status'
import { TabNew } from '../../../components/tab/Tab'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { decryptData, encryptInput } from '../../../utilitys/crypto'
import { dateDisplay, dateTimeDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import AuditResultThreeModal from '../auditresult/components/modal/AuditResultThreeModal.jsx'
import Files from './components/Files.jsx'
import { MainTable, SubTable } from './css/Styles'
import {
  CREATE_AUDIT_CAUSE_OF_ISSUE,
  DELETE_AUDIT_ISSUE,
  DELETE_CAUSE_ISSUE,
  DELETE_SUGGEST,
  EDIT_AUDIT_CAUSE_OF_ISSUE,
  UPSERT_AUDIT_CLOSES
} from './graphql/Mutation'
import {
  AUDITISSUE_FOR_AUDIT_CLOSES_GET,
  AUDIT_CAUSE_OF_ISSUE_FOR_AUDIT_CLOSES_GET,
  AUDIT_PROJECT_FOR_AUDIT_CLOSE,
  AUDIT_SUGGEST_OF_ISSUE_FOR_AUDIT_CLOSES_GET,
  GET_AUDIT_CAUSE_OF_ISSUE_BY_ID,
  GET_AUDIT_CLOSE_BY_CODE
} from './graphql/Query'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24
}

const AuditClosesDetail = (props) => {
  const currentLang = localStorage.getItem('lang')
  const { menu } = props
  const navigate = useNavigate()
  const propsType = useLocation()
  const { refCode, type_action } = propsType.state
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [formCauseOfIssue] = Form.useForm()
  const formName = 'audit_closes'
  const formNameCauseOfIssue = `formCauseOfIssue`
  const { pCode } = decryptData(localStorage.getItem('user'))

  const [visible, setVisible] = useState(false) //modal
  const [objData, setObjData] = useState({
    type: 'add',
    causeId: 0
  })
  const [stateStatementDefaultExpandedRowKeys, setStateStatementDefaultExpandedRowKeys] = useState(null)

  const [selectAuditProject, setSelectAuditProject] = useState([])
  const [dataLists, setDataLists] = useState([])
  const [causeOfIssueList, setCauseOfIssueList] = useState([])
  const [suggestOfIssueList, setSuggestOfIssueList] = useState([])
  const [statementOfIssueList, setStatementOfIssueList] = useState([])
  const [isView, setIsView] = useState(false)
  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const [isDisable, setisDisable] = useState(type_action === 'view' ? true : false)
  const [currentAction, setcurrentAction] = useState(type_action)
  const [rateFacProCode, setrateFacProCode] = useState('')
  const [rateRiskRuleCode, setrateRiskRuleCode] = useState('')
  const [activeTab, setactiveTab] = useState(1)

  const [upSertAuditCloses, { data: upSertAuditClosesData, loading, error: upsertError }] =
    useMutation(UPSERT_AUDIT_CLOSES)
  const [callDelete, deleteResponse] = useMutation(DELETE_AUDIT_ISSUE)
  const [callSaveCauseOfIssue, saveCallOfIssueResponse] = useMutation(CREATE_AUDIT_CAUSE_OF_ISSUE)
  const [callEditCauseOfIssue, editCallOfIssueResponse] = useMutation(EDIT_AUDIT_CAUSE_OF_ISSUE)
  const [callDeleteCauseOfIssue, deleteCauseOfIssueResponse] = useMutation(DELETE_CAUSE_ISSUE)
  const [getAuditCloseByCodeFn, getAuditCloseByCode] = useLazyQuery(GET_AUDIT_CLOSE_BY_CODE)
  const [callCauseOfIssue, causeOfIssue] = useLazyQuery(GET_AUDIT_CAUSE_OF_ISSUE_BY_ID)
  const [auditProjectForAuditCloseFn, auditProjectForAuditClose] = useLazyQuery(AUDIT_PROJECT_FOR_AUDIT_CLOSE)
  const [auditIssueForAuditClosesGetFn, auditIssueForAuditClosesGet] = useLazyQuery(AUDITISSUE_FOR_AUDIT_CLOSES_GET)
  const [auditCauseOfIssueForAuditCloseFn, auditCauseOfIssueForAuditCloseGet] = useLazyQuery(
    AUDIT_CAUSE_OF_ISSUE_FOR_AUDIT_CLOSES_GET
  )
  const [auditSuggestOfIssueForAuditCloseFn, auditSuggestOfIssueForAuditCloseGet] = useLazyQuery(
    AUDIT_SUGGEST_OF_ISSUE_FOR_AUDIT_CLOSES_GET
  )
  const [cellDeleteSuggest, { data: dataDelete, loading: loadingDelete, error: errorDelete }] =
    useMutation(DELETE_SUGGEST)

  useEffect(() => {
    if (dataDelete) {
      if (dataDelete?.auditSuggestDelete) {
        Alert({
          type: 'success',
          title: t('ลบข้อมูลสำเร็จ'),
          onOk() {
            getAuditCloseByCode.refetch()
          }
        })
      }
    }
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถลบข้อมูลได้`),
        content: t(displayError(errorDelete)),
        onOk() {}
      })
    }
  }, [dataDelete])

  useEffect(() => {
    if (type_action === 'edit' || type_action === 'view') {
      getAuditCloseByCodeFn({
        variables: {
          input: encryptInput({
            closeId: refCode.closeId,
            actionPlanId: refCode.actionPlanId,
            issueType: 3
          })
        }
      })

      type_action === 'view' && setIsView(true)
    }
  }, [])

  useEffect(() => {
    setisDisable(currentAction === 'view' ? true : false)
  }, [currentAction])

  useEffect(() => {
    auditProjectForAuditCloseFn({
      variables: {
        input: encryptInput({
          personnelCode: pCode,
          type_action: type_action === 'add' ? 'add' : ''
        })
      }
    })
  }, [])

  useEffect(() => {
    if (causeOfIssue.data) {
      const x = causeOfIssue.data.getAuditCauseOfIssueById
      formCauseOfIssue.setFieldsValue({
        causeId: x.causeId,
        rateFacProCode: x.rateFacProCode,
        rateRiskRuleCode: x.rateRiskRuleCode,
        rateRiskConCode: x.rateRiskConCode,
        issueId: x.issueId,
        causeCode: x.causeCode,
        causeDetail: displayText(x.causeDetailTH, x.causeDetailEN)
      })
    }
  }, [causeOfIssue.data])

  useEffect(() => {
    if (upSertAuditClosesData) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate(`/auditoperations/auditcloses/`)
        }
      })
    }
    if (upsertError) {
      Alert({
        type: 'error',
        title: t(displayError(upsertError)),
        onOk() {}
      })
    }
  }, [upSertAuditClosesData])

  useEffect(() => {
    if (getAuditCloseByCode.data) {
      const auditClose = getAuditCloseByCode.data?.getAuditCloseByCode
      const auditIssueList = getAuditCloseByCode.data?.getAuditCloseByCode?.auditIssueList
      const auditCauseOfIssueList = getAuditCloseByCode.data?.getAuditCloseByCode.auditCauseOfIssueList
      const auditSuggestOfIssueList = getAuditCloseByCode.data?.getAuditCloseByCode.auditSuggestOfIssueList
      const auditStatementOfIssueList = getAuditCloseByCode.data?.getAuditCloseByCode.auditStatementOfIssueList
      //********************************* data tab1 **************************** */
      propsType.state.refCode.projectCode = auditClose.projectCode
      form.setFieldsValue({
        projectCode: auditClose.projectCode,
        // comment: auditClose.comment,
        performance: auditClose.performance,
        effect: auditClose.effect,
        conclusion: auditClose.conclusion,
        dateClose: auditClose.dateClose,
        organizationCode: auditClose.organizationCode,
        auditTeamCode: auditClose.auditTeamCode,
        organizationName: displayText(auditClose.organizationNameTH, auditClose.organizationNameEN),
        personnelCode: auditClose.personnelCode,
        personnelName: displayText(auditClose.fullNameTH, auditClose.fullNameEN)
      })
      //********************************* end data tab1 **************************** */
      //********************************* data tab2 **************************** */
      const dataAuditIssue = auditIssueList.map((item, i) => ({
        ...item,
        key: i++,
        factorsRisks: `${item.rateFacProCode} : ${displayText(item.rateFacProNameTH, item.rateFacProNameEN)}`,
        controlMethod: displayText(item.rateRiskConTH, item.rateRiskConEN),
        issue: displayText(item.issueTH, item.issueEN),
        personResponsible: displayText(item.personnelsNameTH, item.personnelsNameEN)
      }))
      setDataLists(dataAuditIssue)

      //********************************* end data tab2 **************************** */

      // tab 3
      let dataCauseOfIssue = auditCauseOfIssueList.map((x, i) => {
        return {
          ...x,
          key: i++,
          issue: displayText(x.issueTH, x.issueEN),
          causeName: displayText(x.causeNameTH, x.causeNameEN),
          causeDetail: displayText(x.causeDetailTH, x.causeDetailEN),
          rateFacProName: displayText(x.rateFacProNameTH, x.rateFacProNameEN)
        }
      })
      setCauseOfIssueList(dataCauseOfIssue)
      // end tab 3
      // tab 4
      let dataSuggestOfIssue = auditSuggestOfIssueList.map((x, i) => {
        return {
          ...x,
          key: i++,
          issue: displayText(x.issueTH, x.issueEN),
          suggest: displayText(x.suggestTH, x.suggestEN),
          dateExpected: dateDisplay(x.dateExpected),
          description: (
            <SubTable>
              <Datatable
                columns={tableColumns.subSuggestOfIssue}
                data={x.auditSuggestOrganizations.map((item) => {
                  return {
                    key: item.organizationCode + '' + i,
                    no: '',
                    org: item.organizationCode + ' : ' + displayText(item.organizationNameTH, item.organizationNameEN),
                    sendmail: item.sendmail === 1 ? t('ส่งแล้ว') : t('ยังไม่ส่ง'),
                    sendDate: item.sendDate ? dateTimeDisplay(item.sendDate) : '-'
                  }
                })}
              />
            </SubTable>
          ),
          tools: (
            <div style={{ textAlign: 'right' }}>
              <ButtonGroup
                menu={menu}
                icons={[
                  {
                    type: 'view',
                    onClick: () => {
                      navigate('/auditoperations/auditcloses/auditclosesdetail/suggestform', {
                        state: { ...propsType.state, type_actionSub: 'view', suggestId: x.suggestId }
                      })
                    }
                  },
                  {
                    type: 'edit',
                    disabled: type_action === 'view' ? true : false,
                    onClick: () => {
                      navigate('/auditoperations/auditcloses/auditclosesdetail/suggestform', {
                        state: { ...propsType.state, type_actionSub: 'edit', suggestId: x.suggestId }
                      })
                    }
                  },
                  {
                    type: 'delete',
                    disabled: type_action === 'view' ? true : false,
                    onClick: async () => {
                      fnConfirm(x.suggestId)
                    }
                  }
                ]}
              />
            </div>
          )
        }
      })
      setSuggestOfIssueList(dataSuggestOfIssue)
      // end tab 4
      //tab 5
      let dataStatementOfIssue = auditStatementOfIssueList.map((x, i) => {
        return {
          ...x,
          key: i,
          issuesObservations: displayText(x.issueTH, x.issueEN),
          feedback: displayText(x.suggestTH, x.suggestEN),
          dateEn: dateDisplay(x.dateExpected),
          description: (
            <SubTable>
              <Datatable
                columns={tableColumns.subStatementOfIssue}
                data={x?.auditSuggestExplain?.map((item, index) => {
                  return {
                    key: index++,
                    statement: item.explainTH,
                    document:
                      item?.files && item.files?.length
                        ? item.files.map((item, key) => (
                            <div key={key} style={{ marginBottom: '10px' }}>
                              <Files key={key} item={item} index={key} />
                            </div>
                          ))
                        : '',
                    inspectionUnit: item.organizationNameTH,
                    // tools: item.explainId,
                    tools: (
                      <>
                        <ButtonNew
                          type="flagOutlinedInTableBlue"
                          roles={{ type: 'add', menu: menu }}
                          onClick={() => {
                            setObjData({ type: 'view', explainId: item.explainId })
                            setVisible(true)
                            setStateStatementDefaultExpandedRowKeys(i) //แก้ปัญหา react re-render
                          }}
                        >
                          {t('แสดงความคิดเห็น')}
                        </ButtonNew>
                      </>
                    )
                  }
                })}
              />
            </SubTable>
          )
        }
      })
      setStatementOfIssueList(dataStatementOfIssue)
      //end tab 5
    }
    if (getAuditCloseByCode.error) {
      const err = getAuditCloseByCode.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {}
      })
    }
  }, [getAuditCloseByCode.data])

  const fnConfirm = (suggestId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        onSubmitDelete(suggestId)
      },
      onCancel() {}
    })
  }

  const onSubmitDelete = async (suggestId) => {
    let variables = encryptInput({ suggestId: suggestId })
    cellDeleteSuggest({
      variables
    })
  }

  useEffect(() => {
    if (auditProjectForAuditClose?.data) {
      const res = auditProjectForAuditClose?.data?.auditProjectForAuditClose
      const d = res.map((item) => ({
        value: item.projectCode,
        label: item.projectCode + ': ' + displayText(item.projectNameTH, item.projectNameEN),
        row: item
      }))

      setSelectAuditProject(d)
    }
  }, [auditProjectForAuditClose.data])

  useEffect(() => {
    if (auditIssueForAuditClosesGet?.data) {
      // const res = auditIssueForAuditClosesGet?.data?.auditIssueForAuditClosesGet
      // const d = res.map((item, idx) => ({
      //   key: idx + 1,
      //   factorsRisks: item.rateFacProCode + ' : ' + item.rateFacProNameTH,
      //   controlMethod: item.rateRiskConTH,
      //   issues: displayText(item.issueTH, item.issueEN),
      //   fact: displayText(item.factTH, item.factEN),
      //   dateExpected: dateDisplay(item.dateExpected),
      //   suggest: displayText(item.suggestTH, item.suggestEN),
      //   isActive: <Status text={item.isActive === '1' ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
      //   tools: item,
      // }))
      // setDataLists([...d])
      // setSelectAuditProject(d)
      const auditIssueList = auditIssueForAuditClosesGet.data.auditIssueForAuditClosesGet
      const dataAuditIssue = auditIssueList.map((item, i) => {
        return {
          ...item,
          key: i++,
          factorsRisks: displayText(item.rateFacProNameTH, item.rateFacProNameEN),
          controlMethod: displayText(item.rateRiskConTH, item.rateRiskConEN),
          issue: displayText(item.issueTH, item.issueEN),
          personResponsible: displayText(item.personnelsNameTH, item.personnelsNameEN)
        }
      })
      setDataLists(dataAuditIssue)
    }
  }, [auditIssueForAuditClosesGet.data])

  useEffect(() => {
    if (auditCauseOfIssueForAuditCloseGet.data) {
      let data = auditCauseOfIssueForAuditCloseGet.data.auditCauseOfIssueForAuditClosesGet.map((x, i) => {
        return {
          ...x,
          key: i++,
          issue: displayText(x.issueTH, x.issueEN),
          causeName: displayText(x.causeNameTH, x.causeNameEN),
          causeDetail: displayText(x.causeDetailTH, x.causeDetailEN),
          rateFacProName: displayText(x.rateFacProNameTH, x.rateFacProNameEN)
        }
      })
      setCauseOfIssueList(data)
    }
  }, [auditCauseOfIssueForAuditCloseGet.data])

  useEffect(() => {
    if (auditSuggestOfIssueForAuditCloseGet.data) {
      let data = auditSuggestOfIssueForAuditCloseGet.data.auditSuggestOfIssueForAuditCloseGet.map((x, i) => {
        return {
          ...x,
          key: i++,
          issue: displayText(x.issueTH, x.issueEN),
          suggest: displayText(x.suggestTH, x.suggestEN),
          dateExpected: dateDisplay(x.dateExpected)
        }
      })
      setSuggestOfIssueList(data)
    }
  }, [auditSuggestOfIssueForAuditCloseGet.data])

  useEffect(() => {
    if (deleteResponse?.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถลบข้อมูลได้`),
        content: t(displayError(deleteResponse.error)),
        onOk() {}
      })
    }
  }, [deleteResponse])

  // onFinish
  const onFinish = async (_, isSucceed = false) => {
    const upsert = {
      closeId: refCode.closeId,
      projectCode: _?.projectCode,
      // comment: _?.comment,
      isExit: false,
      performance: _?.performance || '',
      effect: _?.effect || '',
      conclusion: _?.conclusion || '',
      dateClose: _?.dateClose,
      auditTeamCode: _?.auditTeamCode,
      isActive: 1,
      organizationCode: _?.organizationCode,
      personnelCode: _?.personnelCode,
      typeAction: type_action === 'edit' ? 'edit' : 'add',
      isSucceed: isSucceed,
      issueType: 1
    }
    if (type_action === 'add') delete upsert.closeId
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk: async () => {
        await upSertAuditCloses({
          variables: {
            input: encryptInput(upsert)
          }
        })
      },
      onCancel() {
        return null
      }
    })
  }
  // onFinishFailed
  const onFinishFailed = () => {
    // set validate organizationList
  }

  const getAuditIssue = async (actionPlanId) => {
    // set validate organizationList
    await auditIssueForAuditClosesGetFn({
      variables: {
        input: encryptInput({
          actionPlanId: actionPlanId,
          issueType: 1
        })
      }
    })

    await auditCauseOfIssueForAuditCloseFn({
      variables: {
        input: encryptInput({ actionPlanId: actionPlanId, issueType: 1 })
      }
    })

    await auditSuggestOfIssueForAuditCloseFn({
      variables: {
        input: encryptInput({ actionPlanId: actionPlanId, issueType: 1 })
      }
    })
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/' },
    { label: t('บันทึกปิดตรวจ (pre exit)'), path: '/auditoperations/auditcloses/' },
    { label: t('รายละเอียดบันทึกปิดตรวจ') }
  ]

  /********************** define column ******************** */

  const columns = [
    { title: t('ปัจจัย/ความเสี่ยง'), dataIndex: 'factorsRisks', width: '350px' },
    { title: t('วิธีการควบคุม'), dataIndex: 'controlMethod', width: '450px' },
    { title: t('ข้อสังเกต'), dataIndex: 'issue', width: '350px' },
    { title: t('ผู้รับผิดชอบประเด็น'), dataIndex: 'personResponsible', width: '200px' },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      width: '100px',
      render: (item) => <Status text={item === '1' ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
    },
    {
      title: '',
      width: '80px',
      render: (record) => {
        const list = [
          {
            type: 'view',
            onClick: () => {
              navigate('auditIssue', {
                state: {
                  action: 'view',
                  issueId: record.issueId,
                  projectCode: refCode.projectCode,
                  actionPlanId: refCode.actionPlanId
                }
              })
            }
          }
        ]

        if (type_action === 'edit') {
          list.push(
            {
              type: 'edit',
              onClick: () =>
                navigate('auditIssue', {
                  state: {
                    action: 'edit',
                    issueId: record.issueId,
                    projectCode: refCode.projectCode,
                    actionPlanId: refCode.actionPlanId
                  }
                })
            },
            {
              type: 'delete',
              onClick: () => {
                Alert({
                  type: 'confirm',
                  title: t('ต้องการยืนยันการลบ?'),
                  onOk() {
                    callDelete({
                      variables: encryptInput({ issueId: record.issueId, projectCode: refCode.projectCode })
                    }).then((result) => {
                      if (result?.data.auditIssueDelete) {
                        Alert({
                          type: 'success',
                          title: t('ลบสำเร็จ'),
                          onOk() {
                            auditIssueForAuditClosesGetFn({
                              variables: {
                                input: encryptInput({
                                  actionPlanId: refCode.actionPlanId,
                                  issueType: 3
                                })
                              }
                            })
                          }
                        })
                      }
                    })
                  },
                  onCancel() {}
                })
              }
            }
          )
        }
        return <ButtonGroup menu={menu} size={18} icons={list} />
      }
    }
  ]

  const tableColumns = {
    causeOfIssue: [
      {
        title: t('ประเด็น/ข้อสังเกต'),
        dataIndex: 'issue',
        width: '20%'
      },
      {
        title: t('ประเภทสาเหตุ'),
        dataIndex: 'causeName'
      },
      {
        title: t('คำอธิบาย'),
        dataIndex: 'causeDetail'
      },
      {
        title: t('ผลกระทบ/ความเสี่ยง'),
        dataIndex: 'rateFacProName'
      },
      {
        title: t('สถานะ'),
        dataIndex: 'isActive',
        render: (item) => <Status text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
      },
      {
        title: '',
        render: (record) => {
          let list = [
            {
              type: 'view',
              onClick: () => {
                setcurrentAction('view')
                setmodalIsOpen(true)
                callCauseOfIssue({
                  variables: encryptInput({ causeId: record.causeId })
                })
              }
            }
          ]
          if (type_action !== 'view') {
            list.push(
              {
                type: 'edit',
                onClick: () => {
                  setcurrentAction('edit')
                  setmodalIsOpen(true)
                  callCauseOfIssue({
                    variables: encryptInput({ causeId: record.causeId })
                  })
                }
              },
              {
                type: 'delete',
                onClick: () => {
                  Alert({
                    type: 'confirm',
                    title: t('ต้องการยืนยันการลบ?'),
                    onOk() {
                      callDeleteCauseOfIssue({
                        variables: {
                          input: encryptInput({ causeId: record.causeId })
                        }
                      }).then((result) => {
                        if (result?.data.SoiAuditCommitteeDeleteCause) {
                          Alert({
                            type: 'success',
                            title: t('ลบสำเร็จ'),
                            onOk() {
                              formCauseOfIssue.resetFields()
                              setmodalIsOpen(false)
                              callCauseOfIssue({
                                variables: encryptInput({ causeId: record.causeId })
                              })
                            }
                          })
                        }
                      })
                    },
                    onCancel() {}
                  })
                }
              }
            )
          }
          return <ButtonGroup menu={props.menu} size={18} icons={list} />
        }
      }
    ],
    suggestOfIssue: [
      {
        title: t('ข้อสังเกต'),
        dataIndex: 'issue',
        width: '40%'
      },
      {
        title: t('ข้อเสนอแนะ'),
        dataIndex: 'suggest',
        width: '35%'
      },
      {
        title: t('วันที่คาดว่าจะแล้วเสร็จ'),
        dataIndex: 'dateExpected',
        width: '15%'
      },
      {
        title: t('สถานะ'),
        dataIndex: 'isActive',
        width: '10%',
        render: (item) => <Status text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
      },
      {
        title: '',
        dataIndex: 'tools',
        width: '10%'
      }
    ],
    subSuggestOfIssue: [
      {
        title: t('หน่วยงาน'),
        dataIndex: 'org',
        width: '35%'
      },
      {
        title: t('สถานะการส่งอีเมล'),
        dataIndex: 'sendmail',
        width: '35%'
      },
      {
        title: t('วันเวลาที่ส่ง'),
        dataIndex: 'sendDate',
        width: '30%'
      }
    ],
    statementOfIssue: [
      {
        title: t('ข้อสังเกต'),
        dataIndex: 'issuesObservations',
        width: '40%'
      },
      {
        title: t('ข้อเสนอแนะ'),
        dataIndex: 'feedback',
        width: '40%'
      },
      {
        title: t('วันที่คาดว่าจะแล้วเสร็จ'),
        dataIndex: 'dateEn',
        width: '20%'
      }
    ],
    subStatementOfIssue: [
      {
        title: t('คำชี้แจง'),
        dataIndex: 'statement',
        width: '30%'
      },
      {
        title: t('เอกสารเพิ่มเติม'),
        dataIndex: 'document',
        width: '30%'
      },
      {
        title: t('หน่วยรับตรวจ'),
        dataIndex: 'inspectionUnit',
        width: '25%'
      },
      {
        dataIndex: 'tools',
        key: 'tools',
        width: '15%'
      }
    ]
  }

  /********************** end column ******************** */

  const tabsDetails = [
    {
      key: 1,
      name: t('ข้อมูลปิดตรวจ'),
      onClick: () => {
        setactiveTab(1)
      },
      content: () => (
        /********************** tab1 ******************** */
        <>
          <Row style={{ marginTop: 24 }} justify="center">
            <Col sm={24} md={24} lg={24}>
              <LabelNew type="body-header">{t('รายละเอียดทั่วไป')}</LabelNew>
            </Col>
          </Row>
          <Row style={{ marginTop: 24 }} justify="center">
            <Col sm={20} md={20} lg={12}>
              <Form.Item
                name="projectCode"
                label={<LabelNew type="tab-header-inactive">{t('โครงการ')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาเลือกโครงการ') }]}
              >
                <Select
                  scrollableId={formName}
                  placeholder={t('เลือกโครงการ')}
                  data={selectAuditProject}
                  onChange={(e) => {
                    const d = selectAuditProject.find((item) => item.value === e).row
                    getAuditIssue(d.actionPlanId)
                    form.setFieldsValue({
                      organizationName: displayText(d.organizationNameTH, d.organizationNameEN),
                      personnelName: displayText(d.fullNameTH, d.fullNameEN),
                      organizationCode: d.organizationCode,
                      personnelCode: d.personnelCode,
                      auditTeamCode: d.auditTeamCode
                    })
                  }}
                  disabled={isView}
                />
              </Form.Item>
              {/* <Form.Item
                name="comment"
                label={<LabelNew type="tab-header-inactive">{t('ข้อสังเกต')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุรหัสแบบประเมิน.') }]}
              >
                <TextAreaNew placeholder={t('ข้อสังเกต')} maxLength={500} rows={3} disabled={isView} />
              </Form.Item> */}
              {/* <Form.Item
                name="performance"
                label={<LabelNew type="tab-header-inactive">{t('สรุปผลการดำเนินงานของผู้บริหาร')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุสรุปผลการดำเนินงานของผู้บริหาร') }]}
              >
                <TextAreaNew
                  placeholder={t('สรุปผลการดำเนินงานของผู้บริหาร')}
                  maxLength={500}
                  rows={3}
                  disabled={isView}
                />
              </Form.Item> */}
              {/* <Form.Item
                name="effect"
                label={<LabelNew type="tab-header-inactive">{t('ผลกระทบ')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุผลกระทบ') }]}
              >
                <TextAreaNew placeholder={t('ผลกระทบ')} maxLength={500} rows={3} disabled={isView} />
              </Form.Item> */}
              {/* <Form.Item
                name="conclusion"
                label={<LabelNew type="tab-header-inactive">{t('สรุปผลการตรวจสอบ')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุสรุปผลการตวจสอบ') }]}
              >
                <TextAreaNew placeholder={t('สรุปผลการตวจสอบ')} maxLength={500} rows={3} disabled={isView} />
              </Form.Item> */}
              <Form.Item
                name={'dateClose'}
                label={<LabelNew type="tab-header-inactive">{t('วันที่ปิดตรวจ (Pre exit)')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุวันที่ปิดตรวจ') }]}
              >
                <Datepicker
                  width={'50%'}
                  setValueDateFn={async (data) => {
                    form.setFieldsValue({ dateClose: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateClose: null })
                  }}
                  autoComplete="off"
                  disabled={isView}
                />
              </Form.Item>
              <Form.Item hidden={true} name="organizationCode">
                <InputNew />
              </Form.Item>
              <Form.Item hidden={true} name="auditTeamCode">
                <InputNew />
              </Form.Item>
              <Form.Item
                name="organizationName"
                label={<LabelNew type="tab-header-inactive">{t('หน่วยที่รับผิดชอบ')}</LabelNew>}
              >
                <InputNew style={{ width: '50%' }} disabled={true} />
              </Form.Item>
              <Form.Item hidden={true} name="personnelCode">
                <InputNew />
              </Form.Item>
              <Form.Item
                name="personnelName"
                label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</LabelNew>}
              >
                <InputNew style={{ width: '50%' }} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: '3em' }}>
            <Col {...Responsive} align="center">
              <Space size={[8, 8]} wrap>
                {!isView && (
                  <>
                    {type_action !== 'add' && (
                      <ButtonNew
                        type="success"
                        roles={{ type: 'edit', menu: menu }}
                        onClick={() => {
                          form
                            .validateFields()
                            .then((v) => onFinish(v, true))
                            .catch((err) => console.log(err))
                        }}
                      >
                        {t('เสร็จสิ้น')}
                      </ButtonNew>
                    )}
                    <ButtonNew
                      type="primary"
                      roles={{ type: 'add', menu: menu }}
                      htmlType="submit"
                      loading={loading ? true : false}
                    >
                      {t('บันทึก')}
                    </ButtonNew>
                  </>
                )}

                <ButtonNew
                  type="back"
                  onClick={() => {
                    navigate('/auditoperations/auditcloses')
                  }}
                >
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </>
      )
      /********************** end tab1 ******************** */
    },
    {
      key: 2,
      name: t('ข้อมูลผลการตรวจ'),
      onClick: () => {
        setactiveTab(2)
      },
      content: () => {
        return (
          /********************** tab2 ******************** */
          <>
            <MainTable>
              {(deleteCauseOfIssueResponse.loading || causeOfIssue.loading) && <SpinnersNew />}
              <Datatable
                columns={columns}
                data={dataLists}
                btnAdd={
                  <>
                    {type_action === 'edit' && (
                      <ButtonNew
                        type="plusTableBorderPrimary"
                        roles={{ type: 'add', menu: props.menu }}
                        style={{ marginRight: '12px' }}
                        onClick={() => {
                          navigate('auditIssue', {
                            state: {
                              action: 'add',
                              actionPlanId: refCode.actionPlanId,
                              projectCode: refCode.projectCode
                            }
                          })
                        }}
                      >
                        {t('บันทึกผลการตรวจสอบ')}
                      </ButtonNew>
                    )}
                  </>
                }
              />
            </MainTable>
            <Row gutter={[16, 16]} style={{ marginTop: '3em' }}>
              <Col {...Responsive} align="center">
                <Space size={[8, 8]} wrap>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/auditoperations/auditcloses')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Space>
              </Col>
            </Row>
          </>
        )
        /********************** end tab2 ******************** */
      }
    },
    {
      key: 3,
      name: t('สาเหตุ'),
      onClick: () => {
        setactiveTab(3)
      },
      content: () => {
        return (
          <>
            <MainTable>
              <Datatable
                columns={tableColumns.causeOfIssue}
                data={causeOfIssueList}
                btnAdd={
                  type_action === 'edit' ? (
                    <>
                      <ButtonNew
                        type="plusTableBorderPrimary"
                        roles={{ type: 'add', menu: props.menu }}
                        style={{ marginRight: '12px' }}
                        onClick={() => {
                          setcurrentAction('add')
                          formCauseOfIssue.resetFields()
                          setmodalIsOpen(true)
                        }}
                      >
                        {t('สาเหตุ')}
                      </ButtonNew>
                    </>
                  ) : (
                    <></>
                  )
                }
              />
            </MainTable>
            <Row gutter={[16, 16]} style={{ marginTop: '3em' }}>
              <Col {...Responsive} align="center">
                <Space size={[8, 8]} wrap>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/auditoperations/auditcloses')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Space>
              </Col>
            </Row>
          </>
        )
      }
    },
    {
      key: 4,
      name: t('ข้อเสนอแนะ'),
      onClick: () => {
        setactiveTab(4)
      },
      content: () => {
        return (
          <>
            <MainTable>
              <Datatable
                columns={tableColumns.suggestOfIssue}
                data={suggestOfIssueList}
                pagination={false}
                scroll={{ x: true, y: false }}
                expandable={{
                  expandedRowRender: (record) => record.description,
                  rowExpandable: (record) => record.name !== 'Not Expandable',
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <IconNew icon={'ChevronUp'} style={{ cursor: 'pointer' }} onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <IconNew
                        icon={'ChevronDown'}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onExpand(record, e)}
                      />
                    )
                }}
                btnAdd={
                  <>
                    {
                      // type_action === 'add' ||
                      type_action === 'edit' ? (
                        <>
                          <ButtonNew
                            type="plusTableBorderPrimary"
                            roles={{ type: 'add', menu: menu }}
                            style={{ marginRight: '12px' }}
                            onClick={() => {
                              navigate('/auditoperations/auditcloses/auditclosesdetail/suggestform', {
                                state: {
                                  ...propsType.state,
                                  type_actionSub: 'add'
                                }
                              })
                            }}
                          >
                            {t('ข้อเสนอแนะ')}
                          </ButtonNew>
                          <ButtonNew
                            onClick={() => {
                              delete propsType.state.suggestId
                              navigate('/auditoperations/auditcloses/auditclosesdetail/emailform', {
                                state: {
                                  ...propsType.state,
                                  type_actionSub: 'add'
                                }
                              })
                            }}
                            type="plusTableBorderPrimary"
                            iconname="Send"
                            width={92}
                            height={36}
                            roles={{ type: 'send', menu: menu }}
                          >
                            {t('แจ้งประเด็น')}
                          </ButtonNew>
                        </>
                      ) : (
                        ''
                      )
                    }
                  </>
                }
              />
            </MainTable>
            <Row gutter={[16, 16]} style={{ marginTop: '3em' }}>
              <Col {...Responsive} align="center">
                <Space size={[8, 8]} wrap>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/auditoperations/auditcloses')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Space>
              </Col>
            </Row>
          </>
        )
      }
    },
    {
      key: 5,
      name: t('คำชี้แจง'),
      onClick: () => {
        setactiveTab(5)
      },
      content: () => {
        return (
          <>
            <MainTable>
              <Datatable
                columns={tableColumns.statementOfIssue}
                data={statementOfIssueList}
                pagination={false}
                scroll={{ x: true, y: false }}
                expandable={{
                  defaultExpandedRowKeys: [stateStatementDefaultExpandedRowKeys], //แก้ปัญหา react re-render
                  expandedRowRender: (record) => record.description,
                  rowExpandable: (record) => record.name !== 'Not Expandable',
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <IconNew icon={'ChevronUp'} style={{ cursor: 'pointer' }} onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <IconNew
                        icon={'ChevronDown'}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onExpand(record, e)}
                      />
                    )
                }}
              />
            </MainTable>
            <Row gutter={[16, 16]} style={{ marginTop: '3em' }}>
              <Col {...Responsive} align="center">
                <Space size={[8, 8]} wrap>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/auditoperations/auditcloses')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Space>
              </Col>
            </Row>
          </>
        )
      }
    }
  ]

  const onSubmit = (data) => {
    let input = {
      ...data
    }
    input[`causeDetail${currentLang.toUpperCase()}`] = data.causeDetail || null

    delete input.causeDetail
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกสาเหตุ'),
      onOk() {
        if (currentAction === 'add') {
          delete input.causeId
          callSaveCauseOfIssue({
            variables: {
              input: encryptInput(input)
            }
          }).then((result) => {
            if (result?.data) {
              Alert({
                type: 'success',
                title: t('บันทึกข้อมูลสำเร็จ'),
                onOk() {
                  formCauseOfIssue.resetFields()
                  setmodalIsOpen(false)
                  getAuditCloseByCodeFn({
                    variables: {
                      input: encryptInput({
                        closeId: refCode.closeId,
                        actionPlanId: refCode.actionPlanId,
                        issueType: 3
                      })
                    }
                  })
                }
              })
            }
          })
        } else {
          callEditCauseOfIssue({
            variables: {
              input: encryptInput(input)
            }
          }).then((result) => {
            if (result?.data) {
              Alert({
                type: 'success',
                title: t('บันทึกข้อมูลสำเร็จ'),
                onOk() {
                  formCauseOfIssue.resetFields()
                  setmodalIsOpen(false)
                  getAuditCloseByCodeFn({
                    variables: {
                      input: encryptInput({
                        closeId: refCode.closeId,
                        actionPlanId: refCode.actionPlanId,
                        issueType: 3
                      })
                    }
                  })
                }
              })
            }
          })
        }
      },
      onCancel() {}
    })
  }

  return (
    <>
      {(getAuditCloseByCode.loading || loadingDelete || deleteResponse.loading) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('บันทึกปิดตรวจ (pre exit)')} />
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          sm: 24,
          md: 24,
          lg: 8,
          xl: 8
        }}
        wrapperCol={{
          sm: 24,
          md: 24,
          lg: 16,
          xl: 16,
          align: 'left'
        }}
        layout="horizontal"
        name={formName}
      >
        <CardNew topic={t('รายละเอียดบันทึกปิดตรวจ')} icon="List" toggledrop={'false'}>
          <TabNew
            tab={tabsDetails}
            activeKey={propsType.state?.activeTab ? propsType.state?.activeTab + '' : activeTab}
            onTabClick={(key) => {
              propsType.state.activeTab = key
            }}
          />
        </CardNew>
      </Form>
      <AuditResultThreeModal open={visible} close={(e) => setVisible(e)} objData={objData} />
      <ModalNew
        visible={modalIsOpen}
        testTitle={t('เพิ่มสาเหตุประเด็น')}
        onClose={() => setmodalIsOpen(false)}
        onSubmit={formCauseOfIssue.submit}
        typeAction={currentAction}
      >
        {(saveCallOfIssueResponse.loading || editCallOfIssueResponse.loading) && <SpinnersNew />}
        <Form
          onFinish={onSubmit}
          form={formCauseOfIssue}
          name={formNameCauseOfIssue}
          autoComplete="off"
          labelAlign="left"
          layout="horizontal"
          labelCol={{
            sm: 24,
            md: 6
          }}
          wrapperCol={{
            sm: 24,
            md: 20
          }}
        >
          <Row>
            <Col offset={2} md={20}>
              <Form.Item hidden={true} name="causeId">
                <InputNew disabled={true} />
              </Form.Item>
              <Form.Item
                name="rateFacProCode"
                label={<LabelNew type="tab-header-inactive">{t('ปัจจัย/ความเสี่ยง')}</LabelNew>}
                rules={[{ required: true, message: t('ปัจจัย/ความเสี่ยง') }]}
              >
                <SelectRateRiskFactorProcessModel
                  placeholder={t('เลือกความเสี่ยงกระบวนการ')}
                  formname={formNameCauseOfIssue}
                  disabled={isDisable}
                  filters={{
                    projectCode: refCode.projectCode,
                    isActive: 1,
                    approveStatusCode: 20
                  }}
                  handleChange={async (e) => {
                    if (e.value) {
                      formCauseOfIssue.setFieldsValue({
                        rateFacProCode: e.value,
                        rateRiskRuleCode: null,
                        rateRiskConCode: null
                      })
                      setrateFacProCode(e.value)
                    } else {
                      formCauseOfIssue.setFieldsValue(null)
                      setrateFacProCode(null)
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="rateRiskRuleCode"
                label={<LabelNew type="tab-header-inactive">{t('เกณฑ์ ระเบียบ คำสั่ง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุเกณฑ์ ระเบียบ คำสั่ง') }]}
              >
                <SelectRateRiskRulesModel
                  placeholder={t('เลือกเกณฑ์ ระเบียบ คำสั่ง')}
                  formname={formNameCauseOfIssue}
                  disabled={isDisable}
                  filters={{ isActive: 1, rateFacProCode: rateFacProCode }}
                  handleChange={(e) => {
                    if (e.value) {
                      formCauseOfIssue.setFieldsValue({
                        rateRiskConCode: null,
                        rateRiskRuleCode: e.value
                      })
                      setrateRiskRuleCode(e.value)
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="rateRiskConCode"
                label={<LabelNew type="tab-header-inactive">{t('วิธีการควบคุม')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุวิธีการควบคุม') }]}
              >
                <SelectRateRiskControl
                  placeholder={t('เลือกวิธีการควบคุม')}
                  formname={formNameCauseOfIssue}
                  disabled={isDisable}
                  filters={{ isActive: 1, rateRiskRuleCode: rateRiskRuleCode }}
                  handleChange={(e) => {
                    if (e.value) {
                      formCauseOfIssue.setFieldsValue({
                        rateRiskConCode: e.value
                      })
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="issueId"
                label={<LabelNew type="tab-header-inactive">{t('ประเด็น/ข้อสังเกต')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุประเด็น/ข้อสังเกต') }]}
              >
                <SelectAuditIssues
                  placeholder={t('ประเด็น/ข้อสังเกต')}
                  formname={formNameCauseOfIssue}
                  disabled={isDisable}
                  filters={{
                    actionPlanId: refCode.actionPlanId,
                    issueType: 3
                  }}
                  handleChange={(e) => {
                    formCauseOfIssue.setFieldsValue({ issueId: e.value || null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="causeCode"
                label={<LabelNew type="tab-header-inactive">{t('สาเหตุ')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุสาเหตุ') }]}
              >
                <SelectCauseOfIssue
                  placeholder={t('เลือกวิธีการควบคุม')}
                  formname={formNameCauseOfIssue}
                  disabled={isDisable}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => {
                    formCauseOfIssue.setFieldsValue({ causeCode: e.value || null })
                  }}
                />
              </Form.Item>
              <Form.Item name="causeDetail" label={<LabelNew type="tab-header-inactive">{t('คำอธิบาย')}</LabelNew>}>
                <TextAreaNew rows={4} placeholder={t('คำอธิบาย')} disabled={isDisable} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalNew>
    </>
  )
}

export default AuditClosesDetail
