/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../../hooks/useLanguage'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew } from '../../../components/input/Input'
import { LabelNew } from '../../../components/label/Label'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { TextAreaNew } from '../../../components/textarea/TextArea.jsx'
import { SpinnersNew } from '../../../components/spinners/Spinners.jsx'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import { convertEmailJson, displayText, displayError } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import SelectTagEmailPersonnels from '../../../components/inputfromapi/selecttagemailpersonnels/SelectTagEmailPersonnels'
import SelectAuditSuggestOrganizationsForAuditCloses from '../../../components/inputfromapi/selectauditsuggestorganizations/SelectAuditSuggestOrganizationsForAuditCloses.jsx'
import { Datatable } from '../../../components/datatable/Datatable'
import { UploadNew } from '../../../components/upload/Upload'
import { SAVE_SEND_EMAIL_SUGGEST } from './graphql/Mutation'
import { GET_AUDIT_CLOSE_SUGGEST_BY_ID, GET_AUDIT_CLOSE_GET_EMAIL_BY_ID } from './graphql/Query'

const Responesive = {
  md: 24,
  lg: 24
}

const EmailFormExit = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/auditcloses'} />
  }

  const [typeAction, setTypeAction] = useState(false)
  const [form] = Form.useForm()
  const [user] = useDecodeUser()

  const [idFile, setIdFile] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(SAVE_SEND_EMAIL_SUGGEST)

  const [callSuggestByID, { data: dataSuggestByID, loading: loadingSuggestByID, error: errorSuggestByID }] =
    useLazyQuery(GET_AUDIT_CLOSE_SUGGEST_BY_ID)

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate('/auditoperations/auditclosesexit/auditclosesexitdetail', {
            state: {
              ...state,
              activeTab: 4
            }
          })
        }
      })
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {}
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (state.type_action) {
      if (state.type_action === 'edit' || state.type_action === 'view') {
        // getSystemUsers()
        if (state.type_action === 'view') {
          setTypeAction(true)
        }
      }
    }
  }, [])

  // onFinish
  const onFinish = (values) => {
    fnConfirm(values)
  }

  const onSubmit = async (values) => {
    let dataInput = {
      organizationCode: values.organizationCode,
      sendTo: convertEmailJson(values.sendTo),
      sendToCopy: convertEmailJson(values.sendToCopy),
      sendToSecret: convertEmailJson(values.sendToSecret),
      detail: values.detail || '',
      subject: values?.subject,
      idFile: idFile,
      sendBy: user?.pCode,
      actionPlanId: state.refCode.actionPlanId,
      projectCode: state.refCode.projectCode,
      refCode: state.refCode.actionPlanId,
      type_action: state.type_action,
      type_actionSub: state.type_actionSub || ''
    }

    cellSave({
      variables: {
        input: encryptInput(dataInput)
      }
    })
  }
  // onFinishFailed
  const onFinishFailed = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t('กรุณากรอกข้อมูลให้ครบถ้วน'),
      onOk() {}
    })
  }

  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {}
    })
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/auditclosesexit' },
    { label: t('บันทึกปิดตรวจ') },
    { label: t('ข้อเสนอแนะ') },
    { label: t('แจ้งประเด็นการตรวจสอบ') }
  ]

  const columns = [
    {
      title: t('ข้อสังเกต'),
      dataIndex: 'issue',
      width: '40%'
    },
    {
      title: t('ข้อเสนอแนะ'),
      dataIndex: 'suggest',
      width: '35%'
    },
    {
      title: t('วันที่คาดว่าจะแล้วเสร็จ'),
      dataIndex: 'dateExpected',
      width: '15%'
    }
  ]

  const [orgSelected, setOrgSelected] = useState('')
  useEffect(() => {
    callSuggestByID({
      variables: {
        input: encryptInput({
          actionPlanId: state.refCode.actionPlanId,
          organizationCode: orgSelected,
          issueType: 4
        })
      }
    })
    callGetEmailByOrg({
      variables: encryptInput({
        organizationCode: orgSelected
      })
    })
  }, [orgSelected])

  const [dataTblSuggest, setDataTblSuggest] = useState([])
  useEffect(() => {
    if (dataSuggestByID) {
      if (dataSuggestByID?.auditSuggestOfIssueForAuditCloseGetByID) {
        const res = dataSuggestByID.auditSuggestOfIssueForAuditCloseGetByID
        const data = res.map((item, i) => {
          return {
            key: i,
            issue: displayText(item.issueTH, item.issueEN),
            suggest: displayText(item.suggestTH, item.suggestEN),
            dateExpected: dateDisplay(item.dateExpected)
          }
        })
        setDataTblSuggest(data)
      }
    }
  }, [dataSuggestByID])

  const [callGetEmailByOrg, { data: dataGetEmailByOrg, loading: loadingGetEmailByOrg, error: errorGetEmailByOrg }] =
    useLazyQuery(GET_AUDIT_CLOSE_GET_EMAIL_BY_ID)

  useEffect(() => {
    if (dataGetEmailByOrg) {
      const dataEmailTo = dataGetEmailByOrg.getAuditSuggestOrganizationsEmailPersonnel.map((item) => {
        const email = decryptData(item.email)
        return JSON.stringify({ personnelCode: item.personnelCode, email: email })
      })
      form.setFieldsValue({ sendTo: dataEmailTo })
    }
  }, [dataGetEmailByOrg])

  return (
    <>
      {loadingSave ? <SpinnersNew /> : null}
      <Breadcrumb data={breadcrumbList} title={t('แจ้งประเด็นข้อเสนอแนะ')} />
      <CardNew topic={t('รายละเอียดการแจ้ง')} icon={'List'} toggledrop={'false'}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            md: 8,
            lg: 6
          }}
          wrapperCol={{
            md: 24,
            lg: 16,
            align: 'left'
          }}
          layout="horizontal"
          name="notification_form"
        >
          <Row style={{ marginBottom: 20 }} justify="start">
            <Col md={2} lg={2}></Col>
            <Col md={22} lg={22}>
              <LabelNew type="sub-header-primary">{t('รายละเอียด')}</LabelNew>
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }} justify="center">
            <Col md={24} lg={16}>
              <Form.Item
                name="organizationCode"
                label={<LabelNew type="tab-header-inactive">{t('หน่วยงาน')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุหน่วยงาน') }]}
              >
                <SelectAuditSuggestOrganizationsForAuditCloses
                  disabled={state.type_action === 'view' ? true : false}
                  formname="notification_form"
                  placeholder={t('เลือกหน่วยงาน')}
                  handleChange={(e) => {
                    setOrgSelected(e?.value)
                    form.setFieldsValue({
                      organizationCode: e?.value
                    })
                  }}
                  onClear={() => {
                    setDataTblSuggest([])
                  }}
                />
              </Form.Item>

              <Form.Item
                name="subject"
                label={<LabelNew type="tab-header-inactive">{t('เรื่อง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุเรื่อง') }]}
              >
                <InputNew maxLength={100} disabled={typeAction} placeholder={t('ระบุเรื่อง')} />
              </Form.Item>
              <Form.Item
                name="sendTo"
                label={<LabelNew type="tab-header-inactive">{t('ถึง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุถึง') }]}
              >
                <SelectTagEmailPersonnels
                  disabled={typeAction}
                  placeholder={t('ระบุถึง')}
                  formname="notification_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ sendTo: e })
                  }}
                />
              </Form.Item>
              <Form.Item name="sendToCopy" label={<LabelNew type="tab-header-inactive">{t('สำเนา')}</LabelNew>}>
                <SelectTagEmailPersonnels
                  disabled={typeAction}
                  placeholder={t('ระบุสำเนา')}
                  formname="notification_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ sendToCopy: e })
                  }}
                />
              </Form.Item>
              <Form.Item name="sendToSecret" label={<LabelNew type="tab-header-inactive">{t('สำเนาลับ')}</LabelNew>}>
                <SelectTagEmailPersonnels
                  disabled={typeAction}
                  placeholder={t('ระบุสำเนาลับ')}
                  formname="notification_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ sendToSecret: e })
                  }}
                />
              </Form.Item>
              <Form.Item name="detail" label={<LabelNew type="tab-header-inactive">{t('รายละเอียด')}</LabelNew>}>
                <TextAreaNew
                  showCount
                  disabled={typeAction}
                  // maxLength={300}
                  rows={5}
                  placeholder={t('ระบุรายละเอียด')}
                />
              </Form.Item>
              <Form.Item label={<LabelNew type="tab-header-inactive">{t('เอกสารแนบ')}</LabelNew>}>
                <UploadNew
                  type="mutiple"
                  setIdFile={setIdFile}
                  idFile={idFile}
                  typeFile="file"
                  actionType="create"
                  idFileDelete={idFileDelete}
                  setIdFileDelete={setIdFileDelete}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="start">
            <Col md={2} lg={2}></Col>
            <Col md={22} lg={22}>
              <LabelNew type="sub-header-primary">{t('ข้อเสนอแนะ')}</LabelNew>
            </Col>
          </Row>
          <Row style={{ marginBottom: 20 }} justify="center">
            <Col md={24} lg={20} align="center">
              <Datatable columns={columns} data={dataTblSuggest} pagination={false} scroll={{ x: true, y: false }} />
            </Col>
          </Row>

          {/* ---------- */}
          <Row gutter={[16, 16]}>
            <Col {...Responesive} align="center">
              <Space size={[8, 8]} wrap>
                {state.type_action !== 'view' ? (
                  <>
                    <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                      {t('บันทึก')}
                    </ButtonNew>
                  </>
                ) : (
                  ''
                )}
                &nbsp;
                <ButtonNew
                  type="back"
                  onClick={() => {
                    navigate('/auditoperations/auditclosesexit/auditclosesexitdetail', {
                      state: {
                        ...state,
                        activeTab: 4
                      }
                    })
                  }}
                >
                  {t('ย้อนกลับ')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </Form>
      </CardNew>
    </>
  )
}
export default EmailFormExit
