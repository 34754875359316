import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { Datatable } from '../../../../components/datatable/Datatable'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonNew } from '../../../../components/button/Button'
import { useTranslation } from 'react-i18next'
import { Status } from '../../../../components/status/Status'
import { GET_AUDIT_SUGGEST } from '../graphql/Query'
import { DELETE_AUDIT_SUGGEST } from '../graphql/Mutation'
import { useLazyQuery, useMutation } from '@apollo/client'
import { encryptInput } from '../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { displayText } from '../../../../utilitys/helper'
import { Alert } from '../../../../components/alert/Alert'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import moment from 'moment'

const SoiAuditCommitteeTabAuditSuggest = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const action = state.action
  const [dataList, setDataList] = useState([])
  const [callData, dataResponse] = useLazyQuery(GET_AUDIT_SUGGEST)
  const [callDelete, deleteResponse] = useMutation(DELETE_AUDIT_SUGGEST)

  useEffect(() => {
    callData({
      variables: encryptInput({
        projectCode: state.projectCode
      })
    })
  }, [])

  useEffect(() => {
    if (dataResponse.data) {
      let i = 1
      const x = dataResponse.data.getAuditSuggest
      const data = x.map((z) => {
        return {
          key: i++,
          ...z,
          suggest: displayText(z.suggestTH, z.suggestEN),
          issue: displayText(z.issueTH, z.issueEN)
        }
      })
      setDataList(data)
    }
  }, [dataResponse.data])

  const columns = [
    {
      title: t('ประเด็น/ข้อสังเกต'),
      dataIndex: 'issue',
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('ข้อเสนอแนะ'),
      dataIndex: 'suggest',
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('วันที่คาดว่าจะแล้วเสร็จ'),
      dataIndex: 'dateExpected',
      width: '10%',
      className: 'text-nowrap',
      render: (item) => moment(item).format('DD/MM/YYYY')
    },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      width: '10%',
      className: 'text-nowrap',
      render: (item) => {
        return <Status text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
      }
    },
    {
      title: '',
      dataIndex: '',
      width: '10%',
      render: (record) => {
        let list = [
          {
            type: 'view',
            onClick: () => {
              navigate('auditSuggest', {
                state: {
                  ...state,
                  action: 'view',
                  suggestId: record.suggestId
                }
              })
            }
          }
        ]
        if (state.action !== 'view') {
          list.push(
            {
              type: 'edit',
              onClick: () => {
                navigate('auditSuggest', {
                  state: {
                    ...state,
                    action: 'edit',
                    suggestId: record.suggestId
                  }
                })
              }
            },
            {
              type: 'delete',
              onClick: () => {
                Alert({
                  type: 'confirm',
                  title: t('ต้องการยืนยันการลบ?'),
                  onOk() {
                    callDelete({
                      variables: {
                        input: encryptInput({ suggestId: record.suggestId })
                      }
                    }).then((result) => {
                      if (result?.data.SoiAuditCommitteeDeleteSuggest) {
                        Alert({
                          type: 'success',
                          title: t('ลบสำเร็จ'),
                          onOk() {
                            callData({
                              variables: encryptInput({
                                projectCode: state.projectCode
                              })
                            })
                          }
                        })
                      }
                    })
                  },
                  onCancel() {}
                })
              }
            }
          )
        }
        return <ButtonGroup menu={props.menu} size={18} icons={list} />
      }
    }
  ]

  return (
    <>
      {(dataResponse.loading || deleteResponse.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataList}
            btnAdd={
              action === 'view' ? null : (
                <>
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: props.menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      navigate('auditSuggest', {
                        state: {
                          ...state,
                          action: 'add'
                        }
                      })
                    }}
                  >
                    {t('ข้อเสนอแนะ')}
                  </ButtonNew>
                </>
              )
            }
          />
        </Col>
      </Row>
    </>
  )
}

export default SoiAuditCommitteeTabAuditSuggest
