import { Trans } from 'react-i18next'

export const columnPlanAndProject = () => {
  return [
    {
      title: <Trans>รหัสแผนการตรวจสอบ</Trans>,
      dataIndex: 'auditPlans_planCode',
      width: '180px'
    },
    {
      title: <Trans>ชื่อแผนการตรวจสอบ</Trans>,
      dataIndex: 'auditPlans_planNameTH',
      width: '200px'
    },
    {
      title: <Trans>ประเภทแผนการตรวจสอบ</Trans>,
      dataIndex: 'auditPlans_planType',
      width: '180px'
    },
    {
      title: <Trans>ประจำปี</Trans>,
      dataIndex: 'auditPlans_planYear',
      width: '80px'
    },
    {
      title: <Trans>วันที่เริ่มต้น</Trans>,
      dataIndex: 'auditPlans_dateStart',
      width: '80px'
    },
    {
      title: <Trans>วันที่สิ้นสุด</Trans>,
      dataIndex: 'auditPlans_dateEnd',
      width: '80px'
    },
    {
      title: <Trans>รหัสโครงการ</Trans>,
      dataIndex: 'auditProjects_projectCode',
      width: '150px'
    },
    {
      title: <Trans>ชื่อโครงการ</Trans>,
      dataIndex: 'auditProjects_projectNameTH',
      width: '200px'
    },
    {
      title: <Trans>ประเภทโครงการ</Trans>,
      dataIndex: 'auditProjects_projectTypeCode',
      width: '160px'
    },
    {
      title: <Trans>แหล่งที่มาของโครงการ</Trans>,
      dataIndex: 'auditProjects_projectRefCode',
      width: '180px'
    },
    {
      title: <Trans>ประเภทการตรวจสอบ</Trans>,
      dataIndex: 'auditProjects_auditTypeCode',
      width: '180px'
    },
    {
      title: <Trans>ผู้อนุมัติแผนการตรวจสอบ</Trans>,
      dataIndex: 'auditProjects_approvePersonnelCode',
      width: '200px'
    },
    {
      title: <Trans>หน่วยงานรับตรวจ</Trans>,
      dataIndex: 'auditProjectOrganizations_organizationCode',
      width: '100px'
    },
    {
      title: <Trans>หน่วยงานที่รับผิดชอบ</Trans>,
      dataIndex: 'auditProjects_organizationCode',
      width: '180px'
    },
    {
      title: <Trans>ทีมตรวจสอบ</Trans>,
      dataIndex: 'auditProjects_auditTeamCode',
      width: '120px'
    },
    {
      title: <Trans>ผู้รับผิดชอบหลัก</Trans>,
      dataIndex: 'auditProjects_personnelCode',
      width: '160px'
    },
    {
      title: <Trans>ขนาดโครงการ</Trans>,
      dataIndex: 'auditProjects_projectSizeCode',
      width: '120px'
    },
    {
      title: <Trans>จำนวนวันทำงาน</Trans>,
      dataIndex: 'auditProjects_manday',
      width: '160px'
    },
    {
      title: <Trans>จำนวนคน</Trans>,
      dataIndex: 'auditProjects_numberOfPeople',
      width: '100px'
    },
    {
      title: <Trans>จำนวนวันทำงานต่อคน</Trans>,
      dataIndex: 'auditProjects_mandayPersonnal',
      width: '160px'
    },
    {
      title: <Trans>วันที่เริ่มต้น</Trans>,
      dataIndex: 'auditProjects_dateStart',
      width: '50px'
    },
    {
      title: <Trans>วันที่เริ่มต้น</Trans>,
      dataIndex: 'auditProjects_dateEnd',
      width: '50px'
    }
  ]
}

export const columnReportExamine = () => {
  return [
    {
      title: <Trans>รหัสโครงการ</Trans>,
      dataIndex: 'auditFinalReport_projectCode',
      width: '150px'
    },
    {
      title: <Trans>เลขที่ตรวจสอบ</Trans>,
      dataIndex: 'auditFinalReport_documentNo',
      width: '150px'
    },
    {
      title: <Trans>วันที่เริ่มต้นรายงาน</Trans>,
      dataIndex: 'auditFinalReport_startDateReport',
      width: '150px'
    },
    {
      title: <Trans>วันที่สิ้นสุดรายงาน</Trans>,
      dataIndex: 'auditFinalReport_endDateReport',
      width: '150px'
    },
    {
      title: <Trans>ข้อสังเกต</Trans>,
      dataIndex: 'auditFinalReportDetail_issue',
      width: '200px'
    },
    {
      title: <Trans>เก็บข้อมูลเกณฑ์ ระเบียบ คำสั่ง ที่เกี่ยวข้อง</Trans>,
      dataIndex: 'auditFinalReportDetail_rules',
      width: '350px'
    },
    {
      title: <Trans>ข้อเท็จจริง</Trans>,
      dataIndex: 'auditFinalReportDetail_fact',
      width: '300px'
    },
    {
      title: <Trans>สาเหตุ</Trans>,
      dataIndex: 'auditFinalReportDetail_cause',
      width: '180px'
    },
    {
      title: <Trans>ความเสี่ยง</Trans>,
      dataIndex: 'auditFinalReportDetail_risk',
      width: '300px'
    },
    {
      title: <Trans>การควบคุมอื่นๆ ที่ทดแทน</Trans>,
      dataIndex: 'auditFinalReportDetail_control',
      width: '180px'
    },
    {
      title: <Trans>สรุปผลตรวจสอบ</Trans>,
      dataIndex: 'auditFinalReportDetail_summaryTest',
      width: '200px'
    },
    {
      title: <Trans>ข้อเสนอแนะ</Trans>,
      dataIndex: 'auditFinalReportDetail_feedback',
      width: '200px'
    },
    {
      title: <Trans>สรุปผลการดำเนินการผู้บริหาร</Trans>,
      dataIndex: 'auditFinalReportDetail_conlusionActionExecutive',
      width: '250px'
    },
    {
      title: <Trans>ผลของการประเมินจากข้อสังเกต</Trans>,
      dataIndex: 'auditFinalReportDetail_resultAssessmentRemark',
      width: '250px'
    }
  ]
}

export const columnAuditCommitteeSummary = () => {
  return [
    {
      title: <Trans>วันที่ประชุม AC</Trans>,
      dataIndex: 'auditResolution_resDate',
      width: '150px'
    },
    {
      title: <Trans>ครั้งที่ประชุม AC</Trans>,
      dataIndex: 'auditResolution_resTimes',
      width: '150px'
    },
    {
      title: <Trans>รหัสโครงการ</Trans>,
      dataIndex: 'auditResolutionAC_projectCode',
      width: '150px'
    },
    {
      title: <Trans>ชื่อวาระ</Trans>,
      dataIndex: 'auditResolutionAC_agendaNameTH',
      width: '200px'
    },
    {
      title: <Trans>เพื่อพิจารณา / เพื่อทราบ</Trans>,
      dataIndex: 'auditResolutionAC_considerCode',
      width: '250px'
    },
    {
      title: <Trans>หน่วยงานที่รับผิดชอบ</Trans>,
      dataIndex: 'auditResolutionAC_organizationCode',
      width: '180px'
    },
    {
      title: <Trans>หน่วยรับตรวจ</Trans>,
      dataIndex: 'auditResolutionOrganizations_organizationCode',
      width: '150px'
    },
    {
      title: <Trans>รหัสปัจจัยความเสี่ยง</Trans>,
      dataIndex: 'auditIssues_rateFacProCode',
      width: '150px'
    },
    {
      title: <Trans>รหัสเกณฑ์</Trans>,
      dataIndex: 'auditIssues_rateRiskRuleCode',
      width: '150px'
    },
    {
      title: <Trans>รหัสวิธีการควบคุม</Trans>,
      dataIndex: 'auditIssues_rateRiskConCode',
      width: '150px'
    },
    {
      title: <Trans>ข้อเท็จจริง</Trans>,
      dataIndex: 'auditIssues_factTH',
      width: '300px'
    },
    {
      title: <Trans>กลุ่มข้อสังเกต</Trans>,
      dataIndex: 'auditIssues_issueGroupType',
      width: '150px'
    },
    {
      title: <Trans>ข้อสังเกต</Trans>,
      dataIndex: 'auditIssues_issueTH',
      width: '250px'
    },
    {
      title: <Trans>ประเภทของข้อสังเกตที่ตรวจพบ</Trans>,
      dataIndex: 'auditIssues_findingTypeCode',
      width: '250px'
    },
    {
      title: <Trans>ความสอดคล้อง</Trans>,
      dataIndex: 'auditIssues_ISOCode',
      width: '150px'
    },
    {
      title: <Trans>ความสอดคล้องย่อย</Trans>,
      dataIndex: 'auditIssues_ISOSubCode',
      width: '150px'
    },
    {
      title: <Trans>ระดับความเสี่ยง</Trans>,
      dataIndex: 'auditIssues_riskLevelCode',
      width: '150px'
    },
    {
      title: <Trans>ความเสี่ยง</Trans>,
      dataIndex: 'auditIssues_riskTH',
      width: '250px'
    },
    {
      title: <Trans>ผลกระทบ</Trans>,
      dataIndex: 'auditIssues_effectTH',
      width: '250px'
    },

    {
      title: <Trans>ผู้รับผิดชอบข้อสังเกต</Trans>,
      dataIndex: 'auditIssuePersonnels_personnelCode',
      width: '180px'
    },
    {
      title: <Trans>สาเหตุ</Trans>,
      dataIndex: 'auditCauseOfIssues_causeCode',
      width: '150px'
    },
    {
      title: <Trans>รายละเอียดของสาเหตุ</Trans>,
      dataIndex: 'auditCauseOfIssues_causeDetailTH',
      width: '250px'
    },
    {
      title: <Trans>กลุ่มข้อเสนอแนะ</Trans>,
      dataIndex: 'auditSuggestOfIssues_suggestGroupType',
      width: '150px'
    },
    {
      title: <Trans>ข้อเสนอแนะ</Trans>,
      dataIndex: 'auditSuggestOfIssues_suggestTH',
      width: '150px'
    },
    {
      title: <Trans>วันที่คาดว่าจะดำเนินการ</Trans>,
      dataIndex: 'auditSuggestOfIssues_dateExpected',
      width: '180px'
    },
    {
      title: <Trans>ผู้รับผิดชอบข้อเสนอแนะ</Trans>,
      dataIndex: 'auditSuggestOfIssues_personnelCode',
      width: '180px'
    },
    {
      title: <Trans>หน่วยงานรับตรวจ</Trans>,
      dataIndex: 'auditSuggestOrganizations_organizationCode',
      width: '180px'
    },
    {
      title: <Trans>เลขที่ ผจ.ลบ</Trans>,
      dataIndex: 'followUp_mdNumber',
      width: '150px'
    },
    {
      title: <Trans>วันที่ส่งรายงานให้หน่วยรับตรวจ ( MD สั่งการ)</Trans>,
      dataIndex: 'followUp_dateSendReport',
      width: '300px'
    },
    {
      title: <Trans>กิจกรรมที่ต้องดำเนินการภาษาไทย</Trans>,
      dataIndex: 'followUpActivity_activityTH',
      width: '250px'
    },
    {
      title: <Trans>วันที่คาดว่าจะเสร็จ</Trans>,
      dataIndex: 'followUpActivity_expectDate',
      width: '150px'
    },
    {
      title: <Trans>หน่วยรับตรวจ</Trans>,
      dataIndex: 'followUpActivity_organizationCode',
      width: '150px'
    },
    {
      title: <Trans>ผู้รับผิดชอบกิจกรรม</Trans>,
      dataIndex: 'followUpActivity_personnelCode',
      width: '150px'
    },
    {
      title: <Trans>รายละเอียดการดำเนินการ</Trans>,
      dataIndex: 'followUpActions_actionDetailTH',
      width: '300px'
    },
    {
      title: <Trans>วันที่ดำเนินการ</Trans>,
      dataIndex: 'followUpActions_actionDate',
      width: '180px'
    },
    {
      title: <Trans>รายละเอียแผนการดำเนินการ</Trans>,
      dataIndex: 'followUpActions_planDetailTH',
      width: '200px'
    },
    {
      title: <Trans>หน่วยรับตรวจ</Trans>,
      dataIndex: 'followUpActions_organizationCode',
      width: '150px'
    },
    {
      title: <Trans>ผู้รับผิดชอบ</Trans>,
      dataIndex: 'followUpActions_personnelCode',
      width: '150px'
    },
    {
      title: <Trans>สถานะของความเห็นชอบในการติดตาม</Trans>,
      dataIndex: 'followUpActions_ideaStatusCode',
      width: '250px'
    },
    {
      title: <Trans>ผู้ให้ความเห็นชอบ</Trans>,
      dataIndex: 'followUpActions_idePersonnelCode',
      width: '150px'
    },
    {
      title: <Trans>สถานะของการดำเนินการ</Trans>,
      dataIndex: 'followUpActions_actionStatusCode',
      width: '180px'
    },
    {
      title: <Trans>สถานะประเด็น/ข้อสังเกต</Trans>,
      dataIndex: 'auditIssues_issueStatusCode',
      width: '180px'
    }
  ]
}
