import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../../components/card/Card.jsx'
import { LabelNew } from '../../../../components/label/Label'
import { GET_ACTION_EXPLAIN_BY_ID } from '../graphql/Query'
import { UPDATE_OR_CREATE_ACTION_EXPLAIN } from '../graphql/Mutation'
import { useMutation, useLazyQuery } from '@apollo/client'
import { displayError } from '../../../../utilitys/helper'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { encryptInput } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { displayText } from '../../../../utilitys/helper'
import { TextAreaNew } from '../../../../components/textarea/TextArea'
import FormActionManageMultiRowFile from '../file/FormActionManageMultiRowFile'
import { ButtonNew } from '../../../../components/button/Button'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import SelectRateRiskRactorProcessByAuditRCM from '../../../../components/inputfromapi/selectrateriskfactorprocessbyauditrcm/SelectRateRiskRactorProcessByAuditRCM'
import SelectAuditIssues from '../../../../components/inputfromapi/selectauditIssues/SelectAuditIssues'
import SelectAuditSuggestOfIssues from '../../../../components/inputfromapi/selectauditsuggestofIssues/SelectAuditSuggestOfIssues'
import SelectFollowUpActivity from '../../../../components/inputfromapi/selectfollowupactivity/SelectFollowUpActivity'
import SelectMasterActionStatus from '../../../../components/inputfromapi/selectmasteractionstatus/SelectMasterActionStatus'
import { useDecodeUser } from '../../../../hooks/useDecodeUser'

const FormActionManage = (props) => {
  const { state } = useLocation()
  if (!state) return <Navigate to={'/followup/actionexplain'} />

  const { t } = useTranslation()
  const navigate = useNavigate()
  const formname = 'followUpActionForm'
  const [formSave] = Form.useForm()
  const [user] = useDecodeUser()

  const [idFileDelete, setIdFileDelete] = useState([])
  const [filesEdit, setFilesEdit] = useState([])
  const [projectCode, setProjectCode] = useState(null)
  const [actionId, setActionId] = useState(null)
  const [formSucceed, setFormSucceed] = useState(0)
  const [rateFacProCode, setRateFacProCode] = useState(null)
  const [issueId, setIssueId] = useState(null)
  const [suggestId, setSuggestId] = useState(null)
  const [isDisableActionStatusCode, setIsDisableActionStatusCode] = useState(null)

  const breadcrumbList = [
    { label: t('งานติดตามผลการตรวจสอบ'), path: '/followup/faollowupaudit' },
    { label: t('ดำเนินการ/ชี้แจง'), path: '/followup/faollowupaudit' },
    { label: t('บันทึกการดำเนินการ/ชี้แจง') }
  ]

  const setErrorField = (name, errors) => {
    // Set field errors
    return formSave.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false // Message errors OR remove fields errors
      }
    ])
  }

  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        if (formSucceed === 1 || state.type === 'add') {
          navigate('/followup/actionexplain/formactionlist', {
            state: { projectCode: state.projectCode }
          })
        }
      }
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }

  const showAlertSaveError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }

  useEffect(() => {
    if (state.projectCode) setProjectCode(state.projectCode)
    if (state.actionId) setActionId(state.actionId)
  }, [state.projectCode, state.actionId])

  const [updateOrCreateActionExplainFn, updateOrCreateActionExplain] = useMutation(UPDATE_OR_CREATE_ACTION_EXPLAIN)

  useEffect(() => {
    if (updateOrCreateActionExplain?.data) {
      let response = updateOrCreateActionExplain?.data?.updateOrCreateActionExplain
      if (response) {
        showAlertSuccess()
      }
    }
    if (updateOrCreateActionExplain.error) {
      showAlertSaveError(displayError(updateOrCreateActionExplain.error))
    }
  }, [updateOrCreateActionExplain.data])

  const [getActionExplainByIdFn, getActionExplainById] = useLazyQuery(GET_ACTION_EXPLAIN_BY_ID)

  useEffect(() => {
    if (getActionExplainById.data) {
      let response = getActionExplainById?.data?.getActionExplainById

      setRateFacProCode(response.rateFacProCode)
      setIssueId(response.issueId)
      setSuggestId(response.suggestId)

      if (response) {
        formSave.setFieldsValue({
          rateFacProCode: response.rateFacProCode,
          issueId: response.issueId,
          suggestId: response.suggestId,
          activityId: response.activityId,
          actionStatusCode: response.actionStatusCode,
          actionDetail: displayText(response.actionDetailTH, response.actionDetailEN),
          planDetail: displayText(response.planDetailTH, response.planDetailEN),
          remark: displayText(response.remarkTH, response.remarkEN),
          actionDate: response.actionDate,
          expectDate: response.expectDate
        })

        const fileDetails = response.fileDetails
          ? JSON.parse(response.fileDetails).map((item) => {
              let fileDetail2 = response.refFile.find((subItem) => {
                return item.fileId === subItem.fileId
              })
              return {
                ...item,
                originalname: fileDetail2 ? fileDetail2.originalname : '',
                name: fileDetail2 ? fileDetail2.name : ''
              }
            })
          : []
        setFilesEdit(fileDetails)
      }
    }

    if (getActionExplainById.error) {
      showAlertLoadDataError(displayError(getActionExplainById.error))
    }
  }, [getActionExplainById.data])

  useEffect(() => {
    if (actionId) {
      getActionExplainByIdFn({
        fetchPolicy: 'no-cache',
        variables: { input: encryptInput({ actionId: actionId }) }
      })
    }
  }, [actionId])

  const onFinish = (data) => {
    let fileInfor = Object.values(data)

    let files = fileInfor
      .filter((item) => item && item.fileId)
      .map((item) => item && item.fileId)
      .join()

    let fileDetail = JSON.stringify(
      fileInfor
        .filter((item) => item && (item.fileId || item.filename || item.links))
        .map((item) => {
          return item
        })
    )

    let dataInput = {
      projectCode: projectCode,
      actionId: actionId,
      activityId: data.activityId,
      actionStatusCode: data.actionStatusCode,
      expectDate: data.expectDate,
      actionDate: data.actionDate,
      formSucceed: formSucceed,
      idFileDelete: idFileDelete,
      remark: data.remark ? data.remark : null,
      planDetail: data.planDetail ? data.planDetail : null,
      actionDetail: data.actionDetail ? data.actionDetail : null,
      files: files,
      fileDetails: fileDetail,
      type: state.type,
      menu: props.menu,
      lang: localStorage.getItem('lang')
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        updateOrCreateActionExplainFn({ variables: { input: encryptInput({ ...dataInput }) } })
      },
      onCancel() {}
    })
  }

  return (
    <>
      {(getActionExplainById.loading || updateOrCreateActionExplain.loading) && <SpinnersNew />}

      <Breadcrumb data={breadcrumbList} title={t('บันทึกการดำเนินการ/ชี้แจง')} menu={props.menu} />

      <Row>
        <Col md={24}>
          <CardNew
            topic={t('บันทึกผลการติดตาม')}
            icon={state.type === 'edit' ? 'Edit2' : 'FileText'}
            toggledrop="false"
          >
            <Form
              onFinish={onFinish}
              form={formSave}
              autoComplete="off"
              labelAlign="left"
              labelCol={{ sm: 24, md: 8 }}
              wrapperCol={{ sm: 24, md: 16 }}
              layout="horizontal"
              name={formname}
            >
              <LabelNew type="body-header">{t('กิจกรรมตรวจสอบ')}</LabelNew>
              <Row justify="center">
                <Col md={18}>
                  <Form.Item
                    name={'rateFacProCode'}
                    label={<LabelNew type="tab-header-inactive">{t('ปัจจัย/ความเสี่ยง')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุปัจจัย/ความเสี่ยง') }]}
                  >
                    <SelectRateRiskRactorProcessByAuditRCM
                      filters={{
                        isActive: 1,
                        force: true,
                        projectCode: projectCode,
                        approveStatusCode: 20,
                        organizationCode: user?.orgCode || ''
                      }}
                      formname={formname}
                      placeholder={t('เลือกปัจจัย/ความเสี่ยง')}
                      disabled={state.type === 'view' ? true : false}
                      handleChange={(value) => {
                        formSave.setFieldsValue({
                          rateFacProCode: value ? value.value : null,
                          issueId: null,
                          suggestId: null,
                          activityId: null
                        })
                        setRateFacProCode(value ? value.value : null)
                        setErrorField('rateFacProCode', value ? false : t('กรุณาเลือกปัจจัย/ความเสี่ยง'))
                        setIssueId(null)
                        setSuggestId(null)
                        setErrorField('issueId', t('กรุณาเลือกประเด็น/ข้อสังเกต'))
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                        setErrorField('activityId', t('กรุณาเลือกกิจกรรมที่ต้องดำเนินการ'))
                      }}
                      onClear={() => {
                        formSave.setFieldsValue({
                          rateFacProCode: null,
                          issueId: null,
                          suggestId: null,
                          activityId: null
                        })
                        setRateFacProCode(null)
                        setIssueId(null)
                        setSuggestId(null)
                        setErrorField('rateFacProCode', t('กรุณาเลือกปัจจัย/ความเสี่ยง'))
                        setErrorField('issueId', t('กรุณาเลือกประเด็น/ข้อสังเกต'))
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                        setErrorField('activityId', t('กรุณาเลือกกิจกรรมที่ต้องดำเนินการ'))
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="issueId"
                    label={<LabelNew type="tab-header-inactive">{t('ประเด็น/ข้อสังเกต')}</LabelNew>}
                    rules={[{ required: true, message: 'กรุณาระบุประเด็น/ข้อสังเกต' }]}
                  >
                    <SelectAuditIssues
                      formname={formname}
                      filters={{
                        projectCode: projectCode,
                        issueType: '2',
                        issueShow: '1',
                        isActive: '1',
                        rateFacProCode: rateFacProCode,
                        force: true
                      }}
                      disabled={state.type === 'view' ? true : false}
                      placeholder={t('เลือกประเด็น/ข้อสังเกต')}
                      handleChange={(value) => {
                        formSave.setFieldsValue({
                          issueId: value ? value.value : null,
                          suggestId: null,
                          activityId: null
                        })
                        setIssueId(value ? value.value : null)
                        setErrorField('issueId', value ? false : t('กรุณาเลือกประเด็น/ข้อสังเกต'))
                        setSuggestId(null)
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                        setErrorField('activityId', t('กรุณาเลือกกิจกรรมที่ต้องดำเนินการ'))
                      }}
                      onClear={() => {
                        formSave.setFieldsValue({
                          issueId: null,
                          suggestId: null,
                          activityId: null
                        })
                        setIssueId(null)
                        setSuggestId(null)
                        setErrorField('issueId', t('กรุณาเลือกประเด็น/ข้อสังเกต'))
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                        setErrorField('activityId', t('กรุณาเลือกกิจกรรมที่ต้องดำเนินการ'))
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="suggestId"
                    label={<LabelNew type="tab-header-inactive">{t('ข้อเสนอแนะ')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุข้อเสนอแนะ') }]}
                  >
                    <SelectAuditSuggestOfIssues
                      formname={formname}
                      filters={{
                        issueId: issueId,
                        rateFacProCode: rateFacProCode,
                        force: true,
                        isActive: '1'
                      }}
                      disabled={state.type === 'view' ? true : false}
                      placeholder={t('เลือกข้อเสนอแนะ')}
                      handleChange={(value) => {
                        formSave.setFieldsValue({ suggestId: value ? value.value : null, activityId: null })
                        setSuggestId(value ? value.value : null)
                        setErrorField('suggestId', value ? false : t('กรุณาเลือกข้อเสนอแนะ'))
                      }}
                      onClear={() => {
                        formSave.setFieldsValue({ suggestId: null, activityId: null })
                        setSuggestId(null)
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                        setErrorField('activityId', t('กรุณาเลือกกิจกรรมที่ต้องดำเนินการ'))
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="activityId"
                    rules={[{ required: true, message: t('กรุณาเลือกกิจกรรมที่ต้องดำเนินการ') }]}
                    label={<LabelNew type="tab-header-inactive">{t('กิจกรรมที่ต้องดำเนินการ')}</LabelNew>}
                  >
                    <SelectFollowUpActivity
                      formname={formname}
                      filters={{
                        issueId: issueId,
                        suggestId: suggestId,
                        rateFacProCode: rateFacProCode,
                        projectCode: projectCode,
                        hasInnerFollowUp: true,
                        hasInnerAuditProject: true,
                        isActive: 1
                      }}
                      disabled={state.type === 'view' ? true : false}
                      placeholder={t('เลือกกิจกรรมที่ต้องดำเนินการ')}
                      handleChange={(data) => {
                        formSave.setFieldsValue({ activityId: data ? data.value : null })
                        setErrorField('activityId', data ? false : t('กรุณาเลือกกิจกรรมที่ต้องดำเนินการ'))
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="actionStatusCode"
                    rules={[{ required: true, message: t('กรุณาเลือกสถานะของการดำเนินการ') }]}
                    label={<LabelNew type="tab-header-inactive">{t('สถานะของการดำเนินการ')}</LabelNew>}
                  >
                    <SelectMasterActionStatus
                      formname={formname}
                      placeholder={t('เลือกสถานะของการดำเนินการ')}
                      disabled={state.type === 'view' ? true : false}
                      handleChange={(data) => {
                        setIsDisableActionStatusCode(data?.value)
                        formSave.setFieldsValue({ actionStatusCode: data ? data.value : null })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="actionDetail"
                    label={<LabelNew type="tab-header-inactive">{t('รายละเอียดของการดำเนินงาน')}</LabelNew>}
                  >
                    <TextAreaNew
                      placeholder={t('ระบุรายละเอียดของการดำเนินงาน')}
                      disabled={state.type === 'view' ? true : isDisableActionStatusCode === '20' ? true : false}
                      rows={6}
                    />
                  </Form.Item>

                  <Form.Item
                    name="actionDate"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่ดำเนินการ')}</LabelNew>}
                    rules={
                      isDisableActionStatusCode === '10' || isDisableActionStatusCode === null
                        ? [{ required: true, message: t('กรุณาเลือกวันที่ดำเนินการ') }]
                        : []
                    }
                  >
                    <Datepicker
                      autoComplete="off"
                      setValueDateFn={async (date) => formSave.setFieldsValue({ actionDate: await date })}
                      disabled={state.type === 'view' ? true : isDisableActionStatusCode === '20' ? true : false}
                      onClear={() => formSave.setFieldsValue({ actionDate: null })}
                      placeholder={t('เลือกวันที่ดำเนินการ')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="planDetail"
                    label={<LabelNew type="tab-header-inactive">{t('แผนการดำเนินงาน')}</LabelNew>}
                  >
                    <TextAreaNew
                      placeholder={t('ระบุแผนการดำเนินงาน')}
                      disabled={state.type === 'view' ? true : isDisableActionStatusCode === '10' ? true : false}
                      rows={6}
                    />
                  </Form.Item>
                  <Form.Item
                    name="expectDate"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่คาดว่าจะแล้วเสร็จ')}</LabelNew>}
                    rules={
                      isDisableActionStatusCode === '20' || isDisableActionStatusCode === null
                        ? [{ required: true, message: t('กรุณาเลือกวันที่คาดว่าจะแล้วเสร็จ') }]
                        : []
                    }
                  >
                    <Datepicker
                      autoComplete="off"
                      setValueDateFn={async (date) => {
                        formSave.setFieldsValue({ expectDate: await date })
                      }}
                      disabled={state.type === 'view' ? true : isDisableActionStatusCode === '10' ? true : false}
                      onClear={() => formSave.setFieldsValue({ expectDate: null })}
                      placeholder={t('เลือกวันที่คาดว่าจะแล้วเสร็จ')}
                    />
                  </Form.Item>
                  <Form.Item name="remark" label={<LabelNew type="tab-header-inactive">{t('หมายเหตุ')}</LabelNew>}>
                    <TextAreaNew
                      placeholder={t('ระบุหมายเหตุ')}
                      disabled={state.type === 'view' ? true : false}
                      rows={6}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <LabelNew type="body-header">{t('เอกสารอ้างอิง')}</LabelNew>

              <FormActionManageMultiRowFile
                form={formSave}
                typeForm={state.type}
                menu={props.menu}
                idFileDelete={idFileDelete}
                files={filesEdit}
                setIdFileDelete={setIdFileDelete}
              />

              <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
                {state.type !== 'view' && (
                  <Col>
                    <ButtonNew
                      type="success"
                      roles={{ type: state.type, menu: props.menu }}
                      onClick={() => setFormSucceed(1)}
                      htmlType="submit"
                    >
                      {t('เสร็จสิ้น')}
                    </ButtonNew>
                  </Col>
                )}
                {state.type !== 'view' && (
                  <Col>
                    <ButtonNew
                      type="primary"
                      roles={{ type: state.type, menu: props.menu }}
                      onClick={() => setFormSucceed(0)}
                      htmlType="submit"
                    >
                      {t('บันทึก')}
                    </ButtonNew>
                  </Col>
                )}
                <Col>
                  <ButtonNew
                    type="cancel"
                    onClick={() => {
                      navigate('/followup/actionexplain/formactionlist', { state: { projectCode: projectCode } })
                    }}
                  >
                    {t('ยกเลิก')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default FormActionManage
