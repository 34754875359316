import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Row, Col } from 'antd'
import * as _ from 'lodash'
import { Datatable } from '../../../../components/datatable/Datatable.jsx'
import { ButtonNew } from '../../../../components/button/Button'
import { SpinnersNew } from '../../../../components/spinners/Spinners.jsx'
import { Alert } from '../../../../components/alert/Alert'
import { displayText, displayError } from '../../../../utilitys/helper'
import { encryptInput } from '../../../../utilitys/crypto'
import { GET_AUDIT_ISSUES_PAGINATION } from '../graphql/Query'
import { SAVE_AUDIT_ISSUE } from '../graphql/Mutation'

const TabFive = (props) => {
  const { t } = useTranslation()
  const { menu } = props
  const [dataLists, setDataLists] = useState([])
  const { state } = useLocation()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sort /*setSort*/] = useState([])
  const [count, setCount] = useState(0)

  if (!state) {
    return <Navigate to={'/auditoperations/auditresult/'} />
  }

  const [pagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] =
    useLazyQuery(GET_AUDIT_ISSUES_PAGINATION)

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(SAVE_AUDIT_ISSUE)

  useEffect(() => {
    if (dataSave) {
      if (dataSave.auditIssuesSave.issueId) {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            pagination({
              variables: {
                input: encryptInput({
                  filters: { projectCode: state.refCode },
                  pagination: { limit, page },
                  sort: sort
                })
              }
            })
          }
        })
      }
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {}
      })
    }
  }, [dataSave])

  useEffect(() => {
    pagination({
      variables: {
        input: encryptInput({
          filters: { projectCode: state.refCode },
          pagination: { limit, page },
          sort: sort
        })
      }
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.auditIssuesPaginationGet
      setCount(response.count)
      setDataLists(
        _.map(response.result, (item, keys) => {
          return {
            key: keys + 1,
            number: keys + 1,
            issuesObservations: displayText(item.issueTH, item.issueEN),
            btnSubmit: { issueId: item.issueId, successd: item.successd }
          }
        })
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {}
      })
    }
  }, [dataPagination])

  const fnConfirm = (issueId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(issueId)
      },
      onCancel() {}
    })
  }

  const onSubmit = async (issueId) => {
    cellSave({
      variables: {
        issueId: encryptInput(issueId)
      }
    })
  }

  const columnsQuestion = [
    {
      title: t('ลำดับ'),
      dataIndex: 'number',
      width: '8%',
      align: 'left'
    },
    {
      title: t('ข้อสังเกต'),
      dataIndex: 'issuesObservations',
      width: '77%',
      align: 'left'
    },
    {
      title: '',
      dataIndex: 'btnSubmit',
      width: '15%',
      align: 'left',
      render: (item) => {
        return item.successd === 0 ? (
          <>
            <ButtonNew
              type="flagOutlinedInTableBlue"
              disabled={state.type_action === 'view' ? true : false}
              roles={{ type: 'add', menu: menu }}
              onClick={() => {
                fnConfirm(item.issueId)
              }}
            >
              {t('บันทึกเสร็จสิ้น')}
            </ButtonNew>
          </>
        ) : (
          <>
            <ButtonNew type="moreHorizontalInTableGray" roles={{ type: 'add', menu: menu }}>
              {t('เสร็จสิ้นแล้ว')}
            </ButtonNew>
          </>
        )
      }
    }
  ]

  return (
    <>
      {(loadingPagination || loadingSave) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={columnsQuestion}
            data={dataLists}
            searchCustom={true}
            total={count}
            pageSize={limit}
            page={page}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
          />
        </Col>
      </Row>
    </>
  )
}

export default TabFive
