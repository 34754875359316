import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { BreadcrumbNew as Breadcrumb } from '../../../../../components/breadcrumb/Breadcrumb'
import { getSegmentUrl } from '../../../../../utilitys/helper'
import { CardNew } from '../../../../../components/card/Card.jsx'
import { LabelNew } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import { DATAFOLLOWUP_RESULT_FOLLOW_TAB_FORM } from '../../graphql/Query'
import { FOLLOW_UP_RESULT_FOLLOW_TAP_SAVE } from '../../graphql/Mutation'
import { useMutation, useLazyQuery } from '@apollo/client'
import { displayError } from '../../../../../utilitys/helper'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { displayText } from '../../../../../utilitys/helper'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { dateDisplay } from '../../../../../utilitys/dateHelper'
import FormFollowUpAuditResultfollowTabFormMultiRow from './FormFollowUpAuditResultfollowTabFormMultiRow'
import SelectIdeaStatus from '../../../../../components/inputfromapi/selectIdeastatus/SelectIdeaStatus.jsx'
import { ButtonNew } from '../../../../../components/button/Button'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'

const FormFollowUpAuditResultfollowTabForm = (props) => {
  const typeMain = getSegmentUrl(4)
  const type = getSegmentUrl(6)
  const { state } = useLocation()
  if (!state) return <Navigate to={'/followup/followupaudit'} />
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [formData] = Form.useForm()
  const [formSave] = Form.useForm()

  const [idFileDelete, setIdFileDelete] = useState([])
  const [filesEdit, setFilesEdit] = useState([])
  const [projectCode, setProjectCode] = useState(null)
  const [ideaSucceed, setSucceed] = useState(0)
  const [activityId, setActivityId] = useState(null)

  const breadcrumbList = [
    { label: t('งานติดตามผลการตรวจสอบ'), path: '/followup/followupaudit' },
    { label: t('ดำเนินการ/ชี้แจง'), path: '/followup/followupaudit' },
    { label: t('บันทึกการดำเนินการ/ชี้แจง') }
  ]
  const showConfirm = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        formSave.submit()
      },
      onCancel() {}
    })
  }

  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        if (ideaSucceed === 1) {
          navigate('/followup/followupaudit/resultfollow/' + typeMain + '/scan/view', {
            state: { refCode: state.refCode }
          })
        }
      }
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }

  const showAlertSaveError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }
  const [followUpResultFollowTabFormSaveFn, followUpResultFollowTabFormSave] = useMutation(
    FOLLOW_UP_RESULT_FOLLOW_TAP_SAVE
  )

  useEffect(() => {
    if (followUpResultFollowTabFormSave?.data) {
      let response = followUpResultFollowTabFormSave?.data?.followUpResultFollowTabFormSave
      if (response) {
        showAlertSuccess()
      }
    }
    if (followUpResultFollowTabFormSave.error) {
      showAlertSaveError(displayError(followUpResultFollowTabFormSave.error))
    }
  }, [followUpResultFollowTabFormSave.data])

  const [dataFollowUpResultFollowTabFormFn, dataFollowUpResultFollowTabForm] = useLazyQuery(
    DATAFOLLOWUP_RESULT_FOLLOW_TAB_FORM
  )

  useEffect(() => {
    if (dataFollowUpResultFollowTabForm.data) {
      let response = dataFollowUpResultFollowTabForm?.data?.dataFollowUpResultFollowTabForm
      if (response) {
        formData.setFieldsValue({
          rateFacProCode:
            response.rateFacProCode + ' : ' + displayText(response.rateFacProNameTH, response.rateFacProNameEN),
          issueId: response.issueId + ' : ' + displayText(response.issueTH, response.issueEN),
          suggestId: response.suggestId + ' : ' + displayText(response.suggestTH, response.suggestEN),
          activityId: response.activityId + ' : ' + displayText(response.activityTH, response.activityEN),
          actionStatusCode:
            response.actionStatusCode + ' : ' + displayText(response.actionStatusNameTH, response.actionStatusNameEN),
          actionDetail: displayText(response.actionDetailTH, response.actionDetailEN),
          actionDate: response.actionDate ? dateDisplay(response.actionDate) : '',
          planDetail: displayText(response.planDetailTH, response.planDetailEN),
          expectDate: response.expectDate ? dateDisplay(response.expectDate) : '',
          remark: displayText(response.remarkTH, response.remarkEN)
        })
        formSave.setFieldsValue({
          ideaStatusCode: response.ideaStatusCode,
          reason: displayText(response.reasonTH, response.reasonEN),
          history: displayText(response.historyTH, response.historyEN)
        })
        const fileDetail = JSON.parse(response.fileDetails)
          ? JSON.parse(response.fileDetails).map((item) => {
              let fileDetail2 = response.refFile.find((subItem) => {
                return item.fileId === subItem.fileId
              })
              return {
                ...item,
                originalname: fileDetail2 ? fileDetail2.originalname : '',
                name: fileDetail2 ? fileDetail2.name : ''
              }
            })
          : []
        setFilesEdit(fileDetail)
        setProjectCode(response?.projectCode)
        setActivityId(response?.activityId)
      }
    }
    if (dataFollowUpResultFollowTabForm.error) {
      showAlertLoadDataError(displayError(dataFollowUpResultFollowTabForm.error))
    }
  }, [dataFollowUpResultFollowTabForm.data])

  useEffect(() => {
    dataFollowUpResultFollowTabFormFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          actionId: state.refCode
        })
      }
    })
  }, [])

  const onFinish = (values) => {
    // ideaStatusCode = 20 (เห็นด้วย) || ideaStatusCode = 30 (ไม่เห็นด้วย)

    const inputDt = {
      activityId: activityId,
      projectCode: projectCode,
      lang: localStorage.getItem('lang'),
      actionId: state.refCode,
      ideaStatusCode: values.ideaStatusCode,
      reason: values.reason,
      history: values.history,
      ideaSucceed: ideaSucceed
    }

    followUpResultFollowTabFormSaveFn({
      variables: { input: encryptInput(inputDt) },
      fetchPolicy: 'no-cache'
    })
  }
  function setErrorField(name, errors) {
    // Set field errors
    return formSave.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false // Message errors OR remove fields errors
      }
    ])
  }

  return (
    <>
      {(dataFollowUpResultFollowTabForm.loading || followUpResultFollowTabFormSave.loading) && <SpinnersNew />}

      <Breadcrumb data={breadcrumbList} title={t('บันทึกการดำเนินการ/ชี้แจง')} menu={props.menu} />

      <Row>
        <Col md={24}>
          <CardNew topic={t('บันทึกผลการติดตาม')} icon={type === 'edit' ? 'Edit2' : 'FileText'} toggledrop="false">
            <Form
              form={formData}
              autoComplete="off"
              labelAlign="left"
              labelCol={{
                sm: 24,
                md: 8
              }}
              wrapperCol={{
                sm: 24,
                md: 16
              }}
              layout="horizontal"
              name="resultFollowForm"
            >
              <LabelNew type="body-header">{t('กิจกรรมตรวจสอบ')}</LabelNew>
              <Row justify="center">
                <Col md={18}>
                  <Form.Item
                    name="rateFacProCode"
                    label={<LabelNew type="tab-header-inactive">{t('ปัจจัย/ความเสี่ยง')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุปัจจัย/ความเสี่ยง')} disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="issueId"
                    label={<LabelNew type="tab-header-inactive">{t('ประเด็น/ข้อสังเกต')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุประเด็น/ข้อสังเกต')} disabled={true} />
                  </Form.Item>
                  <Form.Item name="suggestId" label={<LabelNew type="tab-header-inactive">{t('ข้อเสนอแนะ')}</LabelNew>}>
                    <InputNew placeholder={t('ระบุข้อเสนอแนะ')} disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="activityId"
                    label={<LabelNew type="tab-header-inactive">{t('กิจกรรมที่ต้องดำเนินการ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุกิจกรรมที่ต้องดำเนินการ')} disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="actionStatusCode"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะของการดำเนินการ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุสถานะของการดำเนินการ')} disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="actionDetail"
                    label={<LabelNew type="tab-header-inactive">{t('รายละเอียดของการดำเนินงาน')}</LabelNew>}
                  >
                    <TextAreaNew placeholder={t('ระบุรายละเอียดของการดำเนินงาน')} disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="actionDate"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่ดำเนินการ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุวันที่ดำเนินการ')} disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="planDetail"
                    label={<LabelNew type="tab-header-inactive">{t('แผนการดำเนินงาน')}</LabelNew>}
                  >
                    <TextAreaNew placeholder={t('ระบุแผนการดำเนินงาน')} disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="expectDate"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่คาดว่าจะแล้วเสร็จ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุวันที่คาดว่าจะแล้วเสร็จ')} disabled={true} />
                  </Form.Item>

                  <Form.Item name="remark" label={<LabelNew type="tab-header-inactive">{t('หมายเหตุ')}</LabelNew>}>
                    <TextAreaNew placeholder={t('ระบุหมายเหตุ')} disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <LabelNew type="body-header">{t('เอกสารอ้างอิง')}</LabelNew>
              <FormFollowUpAuditResultfollowTabFormMultiRow
                form={formData}
                typeForm={'view'}
                menu={props.menu}
                idFileDelete={idFileDelete}
                files={filesEdit}
                setIdFileDelete={setIdFileDelete}
              />
            </Form>
          </CardNew>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <CardNew topic={t('บันทึกความเห็น')} icon="" toggledrop="false">
            <Form
              form={formSave}
              autoComplete="off"
              labelAlign="left"
              labelCol={{ sm: 24, md: 8 }}
              wrapperCol={{ sm: 24, md: 16 }}
              layout="horizontal"
              name="resultFollowFormSave"
              onFinish={onFinish}
            >
              <Row justify="center">
                <Col md={18}>
                  <Form.Item
                    name={'ideaStatusCode'}
                    label={<LabelNew type="tab-header-inactive">{t('ข้อคิดเห็น')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาเลือกข้อคิดเห็น') }]}
                  >
                    <SelectIdeaStatus
                      filters={{ isActive: 1 }}
                      formname="resultFollowFormSave"
                      placeholder={t('เลือกข้อคิดเห็น')}
                      disabled={type === 'view' ? true : false}
                      handleChange={(value) => {
                        formSave.setFieldsValue({
                          ideaStatusCode: value ? value.value : null
                        })
                        if (value) {
                          setErrorField('ideaStatusCode', false)
                        } else {
                          setErrorField('ideaStatusCode', t('กรุณาเลือกข้อคิดเห็น'))
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<LabelNew type="tab-header-inactive">{t('เหตุผล')}</LabelNew>}
                    name="reason"
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุเหตุผล')
                      },
                      {
                        max: 200,
                        message: t('กรุณาระบุเหตุผลน้อยกว่าหรือเท่ากับ 200 ตัวอักษร')
                      }
                    ]}
                  >
                    <TextAreaNew
                      maxLength={200}
                      disabled={type === 'view' ? true : false}
                      placeholder={t('ระบุเหตุผล')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<LabelNew type="tab-header-inactive">{t('ประวัติการแก้ไขตามข้อเสนอแนะ')}</LabelNew>}
                    name="history"
                    rules={[
                      {
                        max: 200,
                        message: t('กรุณาระบุประวัติการแก้ไขตามข้อเสนอแนะน้อยกว่าหรือเท่ากับ 200 ตัวอักษร')
                      }
                    ]}
                  >
                    <TextAreaNew
                      maxLength={200}
                      disabled={type === 'view' ? true : false}
                      placeholder={t('ระบุประวัติการแก้ไขตามข้อเสนอแนะ')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
                {type !== 'view' && typeMain !== 'view' && (
                  <Col>
                    <ButtonNew
                      type="success"
                      roles={{ type: type, menu: props.menu }}
                      onClick={() => {
                        setSucceed(1)
                        showConfirm()
                      }}
                      htmlType="button"
                    >
                      {t('เสร็จสิ้น')}
                    </ButtonNew>
                  </Col>
                )}
                {type !== 'view' && typeMain !== 'view' && (
                  <Col>
                    <ButtonNew
                      type="primary"
                      roles={{ type: type, menu: props.menu }}
                      onClick={() => {
                        setSucceed(0)
                        showConfirm()
                      }}
                      htmlType="button"
                    >
                      {t('บันทึก')}
                    </ButtonNew>
                  </Col>
                )}
                <Col>
                  <ButtonNew
                    type="cancel"
                    onClick={() => {
                      navigate('/followup/followupaudit/resultfollow/' + typeMain, {
                        state: { refCode: projectCode }
                      })
                    }}
                  >
                    {t('ยกเลิก')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default FormFollowUpAuditResultfollowTabForm
