import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as _ from 'lodash'
import { Row, Col, Table, Form, Space, Tabs } from 'antd'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { Select } from '../../../components/select/Select'
import { InputNew } from '../../../components/input/Input'
import { LabelNew as Label } from '../../../components/label/Label'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import TabStyle from '../../../components/tab/TabStyles'
import { useLanguage } from '../../../hooks/useLanguage'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'
import { displayError } from '../../../utilitys/helper'
import { GET_ASSESS_RISK_BY_ID } from './graphql/Query'
import { UPDATE_ASSESS_RISK } from './graphql/Mutation'
import { RowStyle } from './css/Styles'

const Assessriskdetail = (props) => {
  const Responsive = {
    card: {
      label: { sm: 12, md: 6, lg: 6 },
      col: { sm: 12, md: 18, lg: 18 }
    }
  }
  const { menu } = props

  const propsType = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const [form] = Form.useForm()
  const { pCode } = decryptData(localStorage.getItem('user'))

  const { refCode, type_action } = propsType.state

  const riskResultId = refCode
  const disabled = type_action === 'view' ? true : false
  const formName = 'riskResultDetail'

  if (!propsType.state) return <Navigate to={'/riskassessment/assessrisk'} />

  const [riskForm, setRiskForm] = useState({})
  const [isMakerBy, setIsMakerBy] = useState(false)
  const [isResponsibilityBy, setIsResponsibilityBy] = useState(false)
  const [isSucceed, setIsSucceed] = useState(false)
  // const [disabled, setDisabled] = useState(type_action === 'view' ? true : false)
  const [isError, setIsError] = useState(false)
  const [dataList, setDataList] = useState({})
  const [refDetail, setRefDetail] = useState([])
  const [isFormular, setIsFormular] = useState([])
  const [uniqRiskFactor, setUniqRiskFactor] = useState([])
  const [tabs, setTabs] = useState([])

  const [assessRiskFn, assessRisk] = useLazyQuery(GET_ASSESS_RISK_BY_ID)

  const [updateAssessRickFn, updateAssessRick] = useMutation(UPDATE_ASSESS_RISK)

  const sharedOnCell = (_) => {
    return {
      colSpan: _.factorActHead ? 0 : 1
    }
  }

  const Columns = [
    {
      title: t('ความเสี่ยง'),
      dataIndex: 'factorActRiskName',
      render: (text, row) => {
        return <>{row.factorActHead ? text : <div style={{ textIndent: '1.5em' }}>{text}</div>}</>
      },
      onCell: (_) => {
        return {
          colSpan: _.factorActHead ? 6 : 1
        }
      }
    },
    {
      title: t('คำอธิบาย'),
      dataIndex: 'remark',
      width: '10%',
      onCell: sharedOnCell
    },
    {
      title: t('โอกาส'),
      dataIndex: 'likelihood',
      width: '10%',
      onCell: sharedOnCell
    },
    {
      title: t('ผลกระทบ'),
      dataIndex: 'impact',
      width: '10%',
      onCell: sharedOnCell
    },
    {
      title: t('ระดับความเสี่ยง'),
      dataIndex: 'Score',
      width: '10%',
      onCell: sharedOnCell
    },
    {
      title: t('ข้อคิดเห็น'),
      dataIndex: 'resultDetail',
      width: '20%',
      onCell: sharedOnCell
    }
  ]

  useEffect(async () => {
    await assessRiskFn({
      variables: { input: encryptInput({ riskResultId: riskResultId }) }
    })
  }, [])

  useEffect(() => {
    if (updateAssessRick.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/riskassessment/assessrisk')
        }
      })
    }
    if (updateAssessRick.error) {
      const err = updateAssessRick.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {
          navigate('/riskassessment/assessrisk')
        }
      })
    }
  }, [updateAssessRick.data])

  useEffect(() => {
    setIsMakerBy(riskForm.makerBy === pCode ? true : false)
    setIsResponsibilityBy(riskForm.responsibilityBy === pCode ? true : false)
  }, [riskForm])

  useEffect(() => {
    if (assessRisk?.data) {
      const tab_ = assessRisk?.data?.getAssessRiskById?.riskResultsFactors
      const children_ = assessRisk?.data?.getAssessRiskById?.riskResultDetail
      const riskFormulars_ = assessRisk?.data?.getAssessRiskById?.riskFormulars

      //** set สูตรการคำนวน **/
      setIsFormular(_.uniqBy(riskFormulars_, 'variableType'))

      const impact = riskFormulars_.filter((e) => e.variableType === 2)
      const likelihood = riskFormulars_.filter((e) => e.variableType === 1)
      const uniqFactorAct_ = _.uniqBy(children_, 'factorActCode')

      setRefDetail(children_)
      setUniqRiskFactor(tab_)

      const children = []
      uniqFactorAct_.map((item) => {
        const x = children_.filter((e) => e.factorActCode === item.factorActCode)
        x.map((item_, idx_) => {
          if (idx_ === 0) {
            children.push(
              {
                ...item_,
                factorActRiskNameTH: item_.factorActNameTH,
                factorActRiskNameEN: item_.factorActNameEN,
                factorActHead: true
              },
              {
                ...item_
              }
            )
          } else {
            children.push({
              ...item_
            })
          }
        })
      })
      setRiskForm(assessRisk?.data?.getAssessRiskById)
      setTabs(
        tab_.map((item, idx) => {
          const impact_ = impact.filter((e) => e.riskFactorCode === item.riskFactorCode)
          const likelihood_ = likelihood.filter((e) => e.riskFactorCode === item.riskFactorCode)
          dataList[item.riskFactorCode] = [
            ...children
              .filter((e) => e.riskFactorCode === item.riskFactorCode)
              .map((item_, idx_) => {
                return {
                  key: idx_ + 1,
                  factorActHead: item_.factorActHead ? item_.factorActHead : null,
                  factorActRiskName: displayText(item_.factorActRiskNameTH, item_.factorActRiskNameEN),
                  remark: item_?.remark,
                  impact: !item_.factorActHead ? (
                    <>
                      <Form.Item hidden={true} name={item_.factorActRiskCode + '_operationType'}>
                        <InputNew disabled={riskForm.operationStatusCode === '22' ? true : disabled} />
                      </Form.Item>
                      <Form.Item
                        initialValue={item_.impact ? item_.impact : undefined}
                        name={item_.factorActRiskCode + '_impact'}
                      >
                        <Select
                          disabled={riskForm.operationStatusCode === '22' ? true : disabled}
                          data={impact_.map((e) => ({ value: e.valueVariable, label: e.valueVariable, source: e }))}
                          scrollableId={formName}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <></>
                  ),
                  likelihood: !item_.factorActHead ? (
                    <>
                      <Form.Item
                        initialValue={item_.likelihood ? item_.likelihood : undefined}
                        name={item_.factorActRiskCode + '_likelihood'}
                      >
                        <Select
                          onChange={async (value) => {
                            const operationType = await likelihood_.find((e) => e.valueVariable === value).operationType
                            if (operationType) {
                              form.setFieldsValue({
                                [`${item_.factorActRiskCode}_operationType`]: operationType
                              })
                            }
                          }}
                          data={likelihood_.map((e) => ({ value: e.valueVariable, label: e.valueVariable, source: e }))}
                          scrollableId={formName}
                          disabled={riskForm.operationStatusCode === '22' ? true : disabled}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <></>
                  ),
                  Score: !item_.factorActHead ? (
                    <>
                      <Form.Item
                        initialValue={item_.Score ? item_.Score : undefined}
                        name={item_.factorActRiskCode + '_Score'}
                      >
                        <InputNew disabled={true} />
                      </Form.Item>
                    </>
                  ) : (
                    <></>
                  ),
                  resultDetail: !item_.factorActHead ? (
                    <>
                      <Form.Item initialValue={item_.resultDetail} name={item_.factorActRiskCode + '_resultDetail'}>
                        <InputNew
                          placeholder={t('ระบุข้อคิดเห็น')}
                          disabled={riskForm.operationStatusCode === '22' ? true : disabled}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <></>
                  )
                }
              })
          ]

          return {
            key: idx + 1,
            name: t(item.riskFactorNameTH),
            content: () => (
              <>
                <RowStyle>
                  <Table
                    rowClassName={(record) => (record.factorActHead ? 'table-row-color' : '')}
                    columns={Columns}
                    dataSource={[...dataList[item.riskFactorCode]]}
                    size="middle"
                    pagination={false}
                  />
                </RowStyle>
              </>
            )
          }
        })
      )
      setDataList({ ...dataList })
    }
    if (assessRisk.error) {
      const err = assessRisk.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {
          navigate('/riskassessment/assessrisk')
        }
      })
    }
  }, [assessRisk.data])

  const onFinish = async (value) => {
    let x = refDetail
      .map((item) => {
        return {
          riskResultId: item.riskResultId,
          riskResulDetailId: item.riskResulDetailId,
          riskFactorCode: item.riskFactorCode,
          factorActCode: item.factorActCode,
          factorActRiskCode: item.factorActRiskCode,
          impact: value[`${item.factorActRiskCode}_impact`],
          likelihood: value[`${item.factorActRiskCode}_likelihood`],
          Score: value[`${item.factorActRiskCode}_Score`],
          resultDetail: value[`${item.factorActRiskCode}_resultDetail`]
        }
      })
      .filter((e) => e?.impact !== undefined && e?.likelihood !== undefined && e?.Score !== undefined)

    if (!x.length) return setIsError(true)

    if (type_action === 'update') {
      // สร้าง Map จากข้อมูลที่อัปเดต (x) เพื่อให้ค้นหาได้เร็วขึ้น
      const updatedDataMap = {}
      x.forEach((item) => {
        updatedDataMap[item.riskResulDetailId] = item
      })

      // สร้างข้อมูลใหม่จาก refDetail ทั้งหมด แต่แทนที่ด้วยข้อมูลจาก x ถ้ามี
      const newData = refDetail
        .map((refItem) => {
          // ถ้ามีข้อมูลอัปเดตสำหรับ riskResulDetailId นี้ ใช้ข้อมูลนั้น
          if (updatedDataMap[refItem.riskResulDetailId]) {
            return updatedDataMap[refItem.riskResulDetailId]
          }

          // ถ้าไม่มีข้อมูลอัปเดต ใช้ข้อมูลเดิมจาก refItem
          return {
            riskResultId: refItem.riskResultId,
            riskResulDetailId: refItem.riskResulDetailId,
            riskFactorCode: refItem.riskFactorCode,
            factorActCode: refItem.factorActCode,
            factorActRiskCode: refItem.factorActRiskCode,
            impact: refItem.impact || 0,
            likelihood: refItem.likelihood || 0,
            Score: refItem.Score || 0,
            resultDetail: refItem.resultDetail
          }
        })
        .filter((refItem) => !!refItem?.impact && !!refItem?.likelihood && !!refItem?.Score)

      // กำหนดค่า x เป็นข้อมูลชุดใหม่
      x = newData
    }

    // calculate and insert likelihood and impact in riskResultFactor
    const d = uniqRiskFactor
      .map((item) => {
        if (x.length) {
          const r_ = x.filter((e) => e?.riskFactorCode === item.riskFactorCode)
          if (r_.length) {
            const l_ = r_.length

            const newLikelihood = Math.floor(
              r_.map((e) => (e?.likelihood ? e.likelihood : 0)).reduce((pv, cv) => pv + cv) / l_
            )
            const newImpact = Math.floor(r_.map((e) => (e?.impact ? e?.impact : 0)).reduce((pv, cv) => pv + cv) / l_)
            const newScore = r_.map((e) => (e?.Score ? e?.Score : 0)).reduce((pv, cv) => pv + cv) / l_

            return {
              // resultScore: newLikelihood && newImpact ? newLikelihood * newImpact : 0,
              ...item,
              likelihood: newLikelihood ? newLikelihood : 0, // ค่าเฉลี่ยโอากส,
              impact: newImpact ? newImpact : 0, // ค่าเฉลี่ยผลกระทบ,
              resultScore: newScore // ค่าเฉลี่ย
            }
          }
        }
      })
      .filter((e) => e?.riskFactorCode !== undefined)

    const d_ = {
      riskResultId: riskForm.riskResultId,
      riskFormCode: riskForm.riskFormCode,
      operationStatusCode: riskForm.operationStatusCode,
      isSucceed: isSucceed,
      isResponsibilityBy: isResponsibilityBy,
      isMakerBy: isMakerBy,
      riskResultFactors: d,
      riskResultDetail: x
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk: async () => {
        await updateAssessRickFn({ variables: { input: encryptInput(d_) } })
      },
      onCancel() {}
    })
  }

  const onValuesChange = async (value) => {
    const get = form.getFieldValue
    const set = form.setFieldsValue
    const key = Object.keys(value)[0].split('_')[1]
    const row = Object.keys(value)[0].split('_')[0]
    setIsError(false)
    if (key === 'impact' || key === 'likelihood') {
      const impact = get(`${row}_impact`)
      const likelihood = get(`${row}_likelihood`)
      if (!likelihood || !impact) return set({ [`${row}_Score`]: undefined })
      const operator = {
        sum: (x, y) => x + y,
        minus: (x, y) => x - y,
        multiply: (x, y) => x * y,
        divide: (x, y) => x / y
      }
      let refNumber = 0
      for (let i = 0; i < isFormular.length; i++) {
        const item = isFormular[i]
        const itemNext = isFormular[i + 1]?.valueVariable
        const param1 = i === 0 ? impact : refNumber
        const param2 = i === 0 ? likelihood : itemNext
        const beforeOutLoop = isFormular.length - 2
        if (item.operationType) {
          switch (item.operationType) {
            case 1:
              refNumber = operator.sum(param1, param2)
              if (i === beforeOutLoop) set({ [`${row}_Score`]: refNumber })
              break
            case 2:
              refNumber = operator.minus(param1, param2)
              if (i === beforeOutLoop) set({ [`${row}_Score`]: refNumber })
              break
            case 3:
              refNumber = operator.multiply(param1, param2)
              if (i === beforeOutLoop) set({ [`${row}_Score`]: refNumber })
              break
            case 4:
              refNumber = operator.divide(param1, param2)
              if (i === beforeOutLoop) set({ [`${row}_Score`]: refNumber })
              break
          }
        }
      }
      // ประเภทตัวดำเนินการ 1 = บวก ,2 = ลบ , 3 = คูณ , 4 = หาร
    }
  }

  const breadcrumbList = [
    { label: t('งานประเมินความเสี่ยง'), path: '/riskassessment/assessrisk' },
    { label: t('ประเมินความเสี่ยง') }
  ]

  return (
    <>
      {(assessRisk?.loading || updateAssessRick?.loading) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('แบบประเมินความเสี่ยง')} />
      <Card topic={t('รายละเอียดแบบประเมิน')} icon="AlertCircle" toggledrop={'true'}>
        <Row justify="center">
          <Col md={20}>
            <Row gutter={[24, 24]}>
              <Col {...Responsive.card.label} align="left">
                <Label type="tab-header-inactive">{t('ประจำปี')}</Label>
              </Col>
              <Col {...Responsive.card.col} align="left">
                <Label type="body-primary">
                  {isLang === 'th' ? String(riskForm.Year + 543) : String(riskForm.Year)}
                </Label>
              </Col>
              <Col {...Responsive.card.label} align="left">
                <Label type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</Label>
              </Col>
              <Col {...Responsive.card.col} align="left">
                <Label type="body-primary">{displayText(riskForm.riskFormTH, riskForm.riskFormEN)}</Label>
              </Col>
              <Col {...Responsive.card.label} align="left">
                <Label type="tab-header-inactive">{t('ประเภทแบบประเมิน')}</Label>
              </Col>
              <Col {...Responsive.card.col} align="left">
                <Label type="body-primary">
                  {riskForm.riskFormType === 1 ? t('ประเมินสรุป') : t('ประเมินรายหน่วยงาน')}
                </Label>
              </Col>
              <Col {...Responsive.card.label} align="left">
                <Label type="tab-header-inactive">{t('หน่วยที่ทำแบบประเมิน')}</Label>
              </Col>
              <Col {...Responsive.card.col} align="left">
                <Label type="body-primary">
                  {displayText(riskForm.organizationNameTH, riskForm.organizationNameEN)}
                </Label>
              </Col>
              <Col {...Responsive.card.label} align="left">
                <Label type="tab-header-inactive">{t('ผู้ทำการประเมิน')}</Label>
              </Col>
              <Col {...Responsive.card.col} align="left">
                <Label type="body-primary">{riskForm.makerByName}</Label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Form
        onFinish={onFinish}
        onValuesChange={(e) => Promise.all([setTimeout(() => onValuesChange(e), 50)])}
        form={form}
        name={formName}
      >
        <Card topic={displayText(riskForm.riskFormTH, riskForm.riskFormEN)} icon="List" toggledrop={'false'}>
          <Row justify="center">
            <Col md={24}>
              {isError && <span style={{ color: 'red' }}>{t('กรุณาประเมินอย่างน้อย 1 รายการ')}</span>}
              {/* <TabNew tab={tabs} /> */}
              <TabStyle>
                <Tabs defaultActiveKey="1">
                  {tabs.map((item) => {
                    return (
                      <Tabs.TabPane tab={t(item.name)} key={item.key}>
                        <item.content />
                      </Tabs.TabPane>
                    )
                  })}
                </Tabs>
              </TabStyle>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: '18pt' }}>
            <Col {...Responsive} align="center">
              <Space size={[8, 8]} wrap>
                {riskForm.operationStatusCode === '22' && isMakerBy ? null : (
                  <>
                    {type_action === 'view' ? null : (
                      <>
                        <ButtonNew
                          type="primary"
                          roles={{ type: 'edit', menu: menu }}
                          onClick={() => setIsSucceed(false)}
                          htmlType="submit"
                        >
                          {t('บันทึก')}
                        </ButtonNew>
                      </>
                    )}
                  </>
                )}
                &nbsp;
                {isMakerBy ? (
                  <>
                    {type_action === 'view' ? null : (
                      <ButtonNew
                        type="success"
                        roles={{ type: 'edit', menu: menu }}
                        onClick={() => setIsSucceed(true)}
                        htmlType="submit"
                      >
                        {t('เสร็จสิ้น')}
                      </ButtonNew>
                    )}
                  </>
                ) : null}
                {isResponsibilityBy ? (
                  <>
                    {type_action === 'view' ? null : (
                      <ButtonNew
                        type="success"
                        roles={{ type: 'edit', menu: menu }}
                        onClick={() => setIsSucceed(true)}
                        htmlType="submit"
                      >
                        {t('เสร็จสิ้น')}
                      </ButtonNew>
                    )}
                  </>
                ) : null}
                &nbsp;
                <ButtonNew type="back" onClick={() => navigate('/riskassessment/assessrisk')}>
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  )
}

export default Assessriskdetail
