import React, { useState, useEffect } from 'react'
import { CardNew } from '../../../components/card/Card'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, Checkbox } from 'antd'
import { LabelNew } from '../../../components/label/Label'
import { useLocation, useNavigate } from 'react-router-dom'
import SelectRateRiskFactorProcessModel from '../../../components/inputfromapi/selectrateriskfactorprocessmodel/SelectRateRiskFactorProcessModel'
import SelectRateRiskRulesModel from '../../../components/inputfromapi/selectrateriskrulemodel/SelectRateRiskRuleModel'
import SelectRateRiskControl from '../../../components/inputfromapi/selectrateriskcontrol/SelectRateRiskControl'
import SelectAuditIssues from '../../../components/inputfromapi/selectauditIssues/SelectAuditIssues'
import SelectPersonnel from '../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import SelectReferInformations from '../../../components/inputfromapi/selectreferInformations/SelectReferInformations'
import SelectOrganizationLevel from '../../../components/inputfromapi/selectorganizationlevel/SelectOrganizationLevel'
import { ButtonNew } from '../../../components/button/Button'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { GET_AUDIT_SUGGEST_BY_ID, GET_ORGANIZATION_PAGINATION } from './graphql/Query'
import { CREATE_AUDIT_SUGGEST, EDIT_AUDIT_SUGGEST } from './graphql/Mutation'
import { useLazyQuery, useMutation } from '@apollo/client'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError, displayText } from '../../../utilitys/helper'
import { Alert } from '../../../components/alert/Alert'
import { InputNew } from '../../../components/input/Input'
import { Datatable } from '../../../components/datatable/Datatable'
import { ModalNew } from '../../../components/modal/Modal'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'

const SoiAuditCommitteeSuggestForm = (props) => {
  const currentLang = localStorage.getItem('lang')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [formFilter] = Form.useForm()
  const formName = 'suggestForm'
  const mainPage = '/auditoperations/soiauditcommittee/view'
  const { state } = useLocation()
  const { action } = state
  const isDisable = action === 'view' ? true : false
  const [rateFacProCode, setrateFacProCode] = useState('')
  const [rateRiskRuleCode, setrateRiskRuleCode] = useState('')
  const [callData, dataResponse] = useLazyQuery(GET_AUDIT_SUGGEST_BY_ID)
  const [callOrg, dataOrg] = useLazyQuery(GET_ORGANIZATION_PAGINATION)
  const [callSave, saveResponse] = useMutation(CREATE_AUDIT_SUGGEST)

  const [callEdit, editReponse] = useMutation(EDIT_AUDIT_SUGGEST)
  const [dataList, setDataList] = useState([])
  const [listOrg, setlistOrg] = useState([])
  const [modalOpen, setmodalOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [searchTrigger, setsearchTrigger] = useState(false)
  // const [sort, setSort] = useState([])

  const formItemResponsive = {
    sm: 24,
    md: 12,
    lg: 8
  }
  const columns = [
    {
      title: t('ลำดับ'),
      dataIndex: 'key',
      width: '5%',
      className: 'text-nowrap'
    },
    {
      title: t('รหัสหน่วยงาน'),
      dataIndex: 'orgCode',
      width: '10%',
      className: 'text-nowrap'
    },
    {
      title: t('หน่วยงาน'),
      dataIndex: 'orgName',
      width: '35%',
      className: 'text-nowrap'
    },
    {
      title: t('ระดับหน่วยงาน'),
      dataIndex: 'orgLV',
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'orgResponsible',
      width: '35%',
      className: 'text-nowrap'
    }
  ]

  const onSubmit = (data) => {
    let input = {
      ...data,
      refInfoCode: data.refInfoCode || null,
      suggestOrg: dataList.map((x) => x.orgCode)
    }
    input[`suggest${currentLang.toUpperCase()}`] = input.suggest
    delete input.suggest

    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกข้อเสนอแนะ'),
      onOk: async () => {
        if (state.action === 'add') {
          delete input.suggestId
          callSave({
            variables: {
              input: { ...encryptInput(input) }
            }
          })
          // .then((result) => {
          // if (result?.data) {
          //   Alert({
          //     type: 'success',
          //     title: t('บันทึกข้อมูลสำเร็จ'),
          //     onOk() {
          //       navigate(mainPage, {
          //         state: state,
          //       })
          //     },
          //   })
          // } else {
          //   Alert({
          //     type: 'error',
          //     // title: t(upsertError.message),
          //     onOk() {},
          //   })
          // }
          // })
        } else if (state.action === 'edit') {
          callEdit({
            variables: {
              input: encryptInput(input)
            }
          })
          // .then((result) => {
          //   if (result?.data) {
          //     Alert({
          //       type: 'success',
          //       title: t('บันทึกข้อมูลสำเร็จ'),
          //       onOk() {
          //         navigate(mainPage, {
          //           state: state,
          //         })
          //       },
          //     })
          //   } else {
          //     Alert({
          //       type: 'error',
          //       // title: t(upsertError.message),
          //       onOk() {},
          //     })
          //   }
          // })
        }
      },
      onCancel: () => null
    })
  }

  useEffect(() => {
    if (saveResponse.data) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate(mainPage, {
            state: state
          })
        }
      })
    }
  }, [saveResponse.data])
  useEffect(() => {
    if (saveResponse.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(saveResponse.error)),
        onOk() {}
      })
    }
  }, [saveResponse.error])

  useEffect(() => {
    if (editReponse.data) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate(mainPage, {
            state: state
          })
        }
      })
    }
  }, [editReponse.data])
  useEffect(() => {
    if (editReponse.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(editReponse.error)),
        onOk() {}
      })
    }
  }, [editReponse.error])

  useEffect(() => {
    if (action !== 'add') {
      callData({
        variables: encryptInput({
          suggestId: state.suggestId
        })
      })
    }
  }, [])

  useEffect(() => {
    if (action !== 'view') {
      callOrg({
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            filters: formFilter.getFieldValue(),
            pagination: { limit, page },
            sort: []
          }
        }
      })
    }
  }, [page, searchTrigger])

  useEffect(() => {
    if (dataResponse.data) {
      const x = dataResponse.data.getAuditSuggestById
      form.setFieldsValue({
        ...x,
        suggest: displayText(x.suggestTH, x.suggestEN)
      })

      if (x?.auditSuggestOrganizations) {
        // let key = 1
        const suggestOrg = x.auditSuggestOrganizations.map((y) => {
          return {
            // key: key++,
            orgCode: y.organizationCode,
            orgName: displayText(y.organizationNameTH, y.organizationNameEN),
            orgLV: displayText(
              y?.masterOrganization.masterOrganizationLevel.organizationLevelNameTH,
              y?.masterOrganization.masterOrganizationLevel.organizationLevelNameEN
            ),
            orgResponsible: `${
              displayText(
                y.masterOrganization.responsiblePerson?.firstNameTH,
                y.masterOrganization?.responsiblePerson?.firstNameTH
              ) || ''
            } ${
              displayText(
                y?.masterOrganization.responsiblePerson?.lastNameTH,
                y.masterOrganization.responsiblePerson?.lastNameTH
              ) || ''
            }`
          }
        })
        setDataList(suggestOrg)
      }
    }
  }, [dataResponse.data])

  useEffect(() => {
    if (dataOrg?.data) {
      const x = dataOrg.data.getOrganizationsPagination.result.map((x, i) => {
        let orgName = displayText(x.organizationNameTH, x.organizationNameEN).split(' : ')
        return {
          key: ++i,
          orgCode: x.organizationCode,
          // orgName: displayText(x.organizationNameTH, x.organizationNameEN),
          orgName: orgName[1],
          orgLV: displayText(x.organizationLevelNameTH, x.organizationLevelNameEN),
          orgResponsible: `${displayText(x.responsiblePerson?.firstNameTH, x.responsiblePerson?.firstNameTH) || ''} ${
            displayText(x.responsiblePerson?.lastNameTH, x.responsiblePerson?.lastNameTH) || ''
          }`
        }
      })
      setlistOrg(x)
      setCount(dataOrg.data.getOrganizationsPagination.count)
    }
  }, [dataOrg.data])

  const renderTextTitle = () => {
    if (action === 'add') {
      return 'เพิ่มข้อเสนอแนะ'
    }
    if (action === 'edit') {
      return 'แก้ไขข้อเสนอแนะ'
    }

    return 'ดูข้อเสนอแนะ'
  }

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/acresolution' },
    { label: t('บันทึกสรุปประเด็นคณะกรรมการตรวจสอบ') },
    { label: t(renderTextTitle()) }
  ]

  return (
    <>
      <BreadcrumbNew data={arrCrumb} title={t(renderTextTitle())} menu={props.menu} />
      {(dataResponse.loading || saveResponse.loading || editReponse.loading) && <SpinnersNew />}
      <Form
        name={formName}
        form={form}
        autoComplete="off"
        labelAlign="left"
        layout="horizontal"
        onFinish={onSubmit}
        labelCol={{
          sm: 24,
          md: 4
        }}
        wrapperCol={{
          sm: 24,
          md: 20
        }}
      >
        <CardNew topic={t(renderTextTitle())} icon="Plus" toggledrop="false">
          <Row>
            <Col md={24}>
              <LabelNew type="body-header">{t('ข้อมูลทั่วไป')}</LabelNew>
              <Row>
                <Col offset={2} md={20}>
                  <Form.Item hidden={true} name="suggestId">
                    <InputNew disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="rateFacProCode"
                    label={<LabelNew type="tab-header-inactive">{t('ปัจจัย/ความเสี่ยง')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุปัจจัย/ความเสี่ยง') }]}
                  >
                    <SelectRateRiskFactorProcessModel
                      placeholder={t('เลือกความเสี่ยงกระบวนการ')}
                      formname={formName}
                      disabled={isDisable}
                      filters={{
                        projectCode: state?.projectCode,
                        isActive: 1,
                        approveStatusCode: 20
                      }}
                      handleChange={async (e) => {
                        if (e.value) {
                          form.setFieldsValue({
                            rateFacProCode: e.value,
                            rateRiskRuleCode: null,
                            rateRiskConCode: null
                          })
                          setrateFacProCode(e.value)
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="rateRiskRuleCode"
                    label={<LabelNew type="tab-header-inactive">{t('เกณฑ์ ระเบียบ คำสั่ง')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุเกณฑ์ ระเบียบ คำสั่ง') }]}
                  >
                    <SelectRateRiskRulesModel
                      placeholder={t('เลือกเกณฑ์ ระเบียบ คำสั่ง')}
                      formname={formName}
                      disabled={isDisable}
                      filters={{ isActive: 1, rateFacProCode: rateFacProCode }}
                      handleChange={(e) => {
                        if (e.value) {
                          form.setFieldsValue({
                            rateRiskConCode: null,
                            rateRiskRuleCode: e.value
                          })
                          setrateRiskRuleCode(e.value)
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="rateRiskConCode"
                    label={<LabelNew type="tab-header-inactive">{t('วิธีการควบคุม')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุวิธีการควบคุม') }]}
                  >
                    <SelectRateRiskControl
                      placeholder={t('เลือกวิธีการควบคุม')}
                      formname={formName}
                      disabled={isDisable}
                      filters={{ isActive: 1, rateRiskRuleCode: rateRiskRuleCode }}
                      handleChange={(e) => {
                        if (e.value) {
                          form.setFieldsValue({
                            rateRiskConCode: e.value
                          })
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="issueId"
                    label={<LabelNew type="tab-header-inactive">{t('ประเด็น/ข้อสังเกต')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุประเด็น/ข้อสังเกต') }]}
                  >
                    <SelectAuditIssues
                      placeholder={t('ประเด็น/ข้อสังเกต')}
                      formname={formName}
                      disabled={isDisable}
                      filters={{
                        projectCode: state.projectCode,
                        issueType: 2
                      }}
                      handleChange={(e) => {
                        form.setFieldsValue({ issueId: e.value || null })
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="suggest" label={<LabelNew type="tab-header-inactive">{t('ข้อเสนอแนะ')}</LabelNew>}>
                    <TextAreaNew placeholder={t('ความเสี่ยง')} disabled={isDisable} />
                  </Form.Item>
                  <Form.Item
                    name="dateExpected"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่คาดว่าจะดำเนินการ')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        form.setFieldsValue({ dateExpected: await data })
                      }}
                      disabled={isDisable}
                      onClear={() => {
                        form.setFieldsValue({ dateExpected: null })
                      }}
                      placeholder={t('เลือกวันที่คาดว่าจะดำเนินการ')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="refInfoCode"
                    label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของข้อเสนอแนะ')}</LabelNew>}
                  >
                    <SelectReferInformations
                      placeholder={t('แหล่งที่มาของข้อเสนอแนะ')}
                      formname={formName}
                      filters={{ isActive: 1 }}
                      disabled={isDisable}
                      handleChange={(e) => form.setFieldsValue({ refInfoCode: e?.value || null })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="personnelCode"
                    label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</LabelNew>}
                  >
                    <SelectPersonnel
                      placeholder={t('เลือกผู้รับผิดชอบ')}
                      formname={formName}
                      filters={{ personnaloperationCode: 10 }}
                      disabled={isDisable}
                      handleChange={(e) => form.setFieldsValue({ personnelCode: e?.value })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardNew>
        <CardNew topic={t('หน่วยงานรับตรวจ')} icon="Plus" toggledrop="false">
          <Row>
            <Col md={24}>
              <Datatable
                columns={columns}
                data={dataList.map((x, i) => {
                  return {
                    ...x,
                    key: ++i
                  }
                })}
                btnAdd={
                  action === 'view' ? null : (
                    <>
                      <ButtonNew
                        type="plusTableBorderPrimary"
                        roles={{ type: 'add', menu: props.menu }}
                        style={{ marginRight: '12px' }}
                        onClick={() => {
                          setmodalOpen(true)
                        }}
                      >
                        {t('เพิ่มหน่วยรับตรวจ')}
                      </ButtonNew>
                    </>
                  )
                }
              />
            </Col>
          </Row>
          <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
            {action !== 'view' && (
              <Col>
                <ButtonNew type="primary" roles={{ type: action, menu: props.menu }} htmlType="submit">
                  {t('บันทึก')}
                </ButtonNew>
              </Col>
            )}
            <Col>
              <ButtonNew
                type="back"
                onClick={() =>
                  navigate(mainPage, {
                    state: {
                      action: action,
                      ...state
                    }
                  })
                }
              >
                {t('ยกเลิก')}
              </ButtonNew>
            </Col>
          </Row>
        </CardNew>
      </Form>
      <Form
        name={'filterOrg'}
        form={formFilter}
        autoComplete="off"
        layout="vertical"
        onFinish={(data) => {
          const selectedOrg = []
          for (const [key, value] of Object.entries(data)) {
            if (key.search('selectedOrg_') !== -1 && value) {
              selectedOrg.push(value)
            }
          }
          const newList = [...dataList, ...selectedOrg]
          setDataList(newList)
          setmodalOpen(false)
        }}
      >
        <ModalNew
          visible={modalOpen}
          testTitle={t('เพิ่มหน่วยรับตรวจ')}
          onClose={() => setmodalOpen(false)}
          onSubmit={formFilter.submit}
          typeAction={'add'}
        >
          {dataOrg.loading && <SpinnersNew />}

          <Row gutter={[12, 12]}>
            <Col {...formItemResponsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('รหัสหน่วยงาน')}</LabelNew>}
                name="organizationCode"
              >
                <InputNew placeholder={t('รหัสหน่วยงาน')} />
              </Form.Item>
            </Col>
            <Col {...formItemResponsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('ชื่อหน่วยงาน')}</LabelNew>}
                name="organizationName"
              >
                <InputNew placeholder={t('ชื่อหน่วยงาน')} />
              </Form.Item>
            </Col>
            <Col {...formItemResponsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('ระดับหน่วยงาน')}</LabelNew>}
                name="organizationLevel"
              >
                <SelectOrganizationLevel
                  formname="filterOrg"
                  placeholder={t('เลือกระดับหน่วยงาน')}
                  handleChange={(e) => {
                    formFilter.setFieldsValue({ organizationLevel: e?.value || null })
                  }}
                  disabled={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew
                type="search"
                onClick={() => {
                  setsearchTrigger(!searchTrigger)
                }}
                roles={{ type: 'view', menu: props.menu }}
              >
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => formFilter.resetFields()}>
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Datatable
                paginationCustom={true}
                searchCustom={true}
                pageSize={limit}
                page={page}
                total={count}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                columns={[
                  {
                    title: t('เลือก'),
                    width: '10%',
                    className: 'text-nowrap',
                    render: (record) => {
                      return (
                        <Form.Item
                          wrapperCol={{
                            md: 24,
                            lg: 24
                          }}
                          name={`selectedOrg_${record.orgCode}`}
                          valuePropName="checked" // ต้องมี
                          style={{ margin: '0px' }}
                        >
                          <Checkbox
                            checked={record.orgCode}
                            onChange={() => {
                              formFilter.setFieldsValue({
                                [`selectedOrg_${record.orgCode}`]: record
                              })
                            }}
                          />
                        </Form.Item>
                      )
                    }
                  },
                  {
                    title: t('หน่วยงาน'),
                    width: '20%',
                    className: 'text-nowrap',
                    dataIndex: 'orgCode'
                  },
                  {
                    title: t('หน่วยงาน'),
                    width: '50%',
                    className: 'text-nowrap',
                    dataIndex: 'orgName'
                  },
                  {
                    title: t('ระดับหน่วยงาน'),
                    width: '20%',
                    className: 'text-nowrap',
                    dataIndex: 'orgLV'
                  }
                ]}
                data={listOrg.filter((x) => dataList.map((x) => x.orgCode).indexOf(x.orgCode) === -1)}
              />
            </Col>
          </Row>
        </ModalNew>
      </Form>
    </>
  )
}

export default SoiAuditCommitteeSuggestForm
